import React from 'react';
import { Avatar, Button, CardHeader, Divider, Typography , Box, Grid} from '@mui/material';
import GetInvoice from './GetInvoice';

import PDFIcon from '@mui/icons-material/PictureAsPdf';
import SendIcon from '@mui/icons-material/Send';
import QrCodeIcon from '@mui/icons-material/QrCode';
import Stack from '@mui/material/Stack';
import ToolBar from './ToolBar';

export default function ReadInvoice(props) { 
  const invoice = props.invoice;

  return (
    <div style={{marginTop: -50}}>
      <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid xs={10}>
          
            <CardHeader sx={{height:175}}
            avatar={
                <Avatar 
                  sx={{ bgcolor: "#051e34" }} 
                  aria-label="recipe" 
                  //src={ client.logo_url !== null ? client.logo_url : ""}
                  variant="rounded" ><QrCodeIcon />
                  {/*client.legal_name.charAt(0).toUpperCase()*/}
                </Avatar>
                
            }
            title={
            <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
              {invoice.uuid}
              <Divider />
            </Typography>
          }
            subheader={invoice.fechaTimbrado}
          />
         
        </Grid>
        <Grid item xs={2}>
          <br /><br />
          <ToolBar invoice={props.invoice} />
        </Grid>
      </Grid>
      </Box>
    {<GetInvoice invoice={props.invoice} />}
    </div>
  )
}
