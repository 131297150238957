import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import { Button, TextField, Grid, Typography, Alert, Snackbar, CardHeader,  ListItemButton, ListItemAvatar, ListItem, Dialog, DialogContent, DialogContentText, DialogActions, Card, CardContent } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams, useNavigate } from 'react-router-dom';
import LectorQR from "../QRReader/QRReader";
import { Box } from "@mui/system";
//import DeleteProduct from "./DeleteProduct";

// taxes
const catTaxes = [
    {
        value: '001',
        label: 'IVA - (0.00%)',
    },
    {
        value: '002',
        label: 'IVA - (16.00%)',
    },
    {
        value: '003',
        label: 'IEPS - (0.00%)',
    },
    {
        value: '004',
        label: 'IEPS - (8.00%)',
    },
    {
        value: '005',
        label: 'Exento - (0.00%)',
    }
];

export default function GetInvoice(props) {
  const [open, setOpen] = React.useState(false);
  const { id } = useParams(); // Unpacking and retrieve id
  const navigate = useNavigate();
  
  const  invoice = props.invoice;
  
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{marginTop: -50}}>
   
        <Fragment>
        {/*
        <Typography variant="body2" color="text.secondary" style={{marginTop:-10}}>
            <Alert severity="info"> Asegúrate que la información que captures en los campos requeridos (*), coincida con la que aparece en la <b>constancia
            de situación fiscal</b> emitida por el SAT. </Alert>
        </Typography>
        */}
         <Alert severity="info" icon={false}>
            1. Datos generales de la factura
        </Alert>
        <Grid container spacing={1}>
            <Grid xs={12} p={0}> 
            <br />
                <Grid xs={12}>
                    <Grid item container spacing={1}> 
                        <Grid item xs={6}>
                        <Card>
                            <CardContent sx={{borderLeft: 5, borderLeftColor: "#F0F0F0"}}>
                                <Typography variant="body2" color="text.secondary">
                                <b>Información del receptor</b>
                                </Typography>
                                <Typography variant="body2" color="text.primary">
                                RFC: <b>{invoice.customer?.tax_id}</b><br />
                                Nombre: <b>{invoice.customer?.legal_name}</b><br />
                                Régimen fiscal: <b>{invoice.customer?.tax_system}</b><br />
                                Código postal: <b>{invoice.customer?.address?.zip}</b><br />
                                </Typography>
                            </CardContent>
                        </Card>  
                        </Grid>
                        <Grid item xs={6}>
                        <Card>
                            <CardContent sx={{borderLeft: 5, borderLeftColor: "#F0F0F0"}}>
                                <Typography variant="body2" color="text.secondary">
                                <b>Información del comprobante</b>
                                </Typography>
                                <Typography variant="body2" color="text.primary">
                                Folio fiscal: <b>{invoice.uuid}</b><br />
                                Fecha de timbrado: <b>{invoice.fechaTimbrado}</b><br />
                                Certificado Digital SAT: <b>{invoice.noCertificadoSAT}</b><br />
                                Certificado CFDI: <b>{invoice.noCertificadoCFDI}</b><br />
                                </Typography>
                            </CardContent>
                        </Card>  
                        </Grid>
                    </Grid>
                </Grid>
                <br />
                <Card style={{marginBottom: 8}}>
                    <CardContent sx={{borderLeft: 5, borderLeftColor: "#F0F0F0"}}>
                        <Typography variant="body2" color="text.secondary">
                        <table border="0" width="95%">
                            <tr>
                                <td valign="top">
                                    <b>Uso del comprobante</b> <br />
                                    {invoice.use} 
                                </td>
                                <td valign="top">
                                    <b>Método de Pago</b> <br />
                                    {invoice.payment_method} 
                                </td>
                                <td valign="top">
                                    <b>Forma de Pago</b> <br />
                                    {invoice.payment_form} 
                                </td>
                                <td valign="top">
                                    <b>Moneda</b> <br />
                                    {invoice.currency} 
                                </td>
                                <td valign="top" align="right">
                                    <b>Importe total</b> <br />
                                    ${invoice.total} 
                                </td>
                            </tr>
                        </table>
                        </Typography>
                    </CardContent>
                </Card> 
                <br />
                <Card style={{marginBottom: 8}}>
                    <CardContent sx={{borderLeft: 5, borderLeftColor: "#F0F0F0"}}>
                        <Typography variant="body2" color="text.secondary">
                        <table border="0" width="100%">
                            <tr>
                                <td valign="top">
                                    
                                    <table width="100%">
                                        <tr>
                                            <th>Producto o servicio</th>
                                            <th>Descrpción</th>
                                            <th>Unidad</th>
                                            <th>Cantidad</th>
                                            <th>Valor unitario</th>
                                            <th>Subtotal</th>
                                        </tr>
                                        <tbody>
                                            <Fragment>
                                            {
                                                invoice.items?.map( item => (
                                                    <tr>
                                                        <td>{item?.product_key}</td>
                                                        <td>{item?.description}</td>
                                                        <td align="center">{item?.unit}</td>
                                                        <td align="right">{item?.quantity}</td>
                                                        <td align="right">{item?.price}</td>
                                                        <td align="right">{item?.subtotal}</td>
                                                    </tr>
                                                ))
                                            }
                                            </Fragment>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </table>
                        </Typography>
                    </CardContent>
                </Card> 
                <br />
                
            </Grid>
            <Grid xs={12}>
            <Card style={{marginBottom: 8}}>
                <CardContent sx={{borderLeft: 5, borderLeftColor: "#F0F0F0"}}>
                    <Typography variant="body2" color="text.secondary" paragraph>
                    <table border="0" width="100%">
                        <tr>
                            <td width="10%">
                            <LectorQR qrCode={"https://verificacfdi.facturaelectronica.sat.gob.mx/"} />
                            </td>
                            <td valign="top">
                                <br />
                                <b>UUID</b> <br />
                                {invoice.uuid} <br /><br />
                                <b>Cadena Original SAT</b> <br />
                                <div style={{width: 200 }}>
                                    {invoice.cadenaOriginalSAT} 
                                </div>  
                                <br /><br />
                            </td>
                        </tr>
                    </table>
                    </Typography>
                </CardContent>
                </Card> 
            </Grid>
            
        </Grid>
        <br />
        <div align="left">
            {/*
            <Grid xs={12}>
                <Button onClick={() => navigate(`/organizations/${id}/facturas/${product._id}/edit`)} 
                style={{marginLeft: 10}} color="primary" variant="contained" startIcon={<EditIcon />}>Editar</Button>
            </Grid>
            */}
            <br />
            <Grid xs={12}>
                <h3>Danger zone</h3>
                    <ListItem
                        sx={{background: "rgb(255, 238, 237)"}}
                        //secondaryAction={ <DeleteProduct product_key={product.product_key} />}
                        disablePadding
                    >
                        <ListItemButton>
                            <ListItemAvatar>
                                <CardHeader sx={{height:75}}
                                title={<Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{"Eliminar producto"}</Typography>}
                                subheader={"Esta acción eliminará permanentemente al producte " + /*product.product_key*/" " + ". Clic en el botón eliminar para continuar con esta acción."}
                                />
                            </ListItemAvatar>
                        </ListItemButton>
                    </ListItem>
            </Grid>
        </div>
        </Fragment>
    </div>
  );
}
