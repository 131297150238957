import React from 'react';
import { Routes, Route } from 'react-router-dom';
import WebPage from '../components/WebPage/WebPage';
// all views
import NotFound from '../views/NotFound';
import Footer from '../components/template/Footer';
import Dashboard from '../views/Dashboard/Index';
import Home from '../views/Home/Home';
import ViewOrgDetail from '../views/Organizations/ViewOrgDetail';
import NotAllowed from '../NotAllowed';


//page router for web page
export const PageRouter = () => {
    return (
    <Routes>
            <Route path='/' element={<WebPage />} />
    </Routes>
    )
}

// app router for subdomain application
export const AppRouter = () => {
  return (
    <>
       <Routes>
            <Route path='*' element={<NotFound />} />
            <Route path='/notallowed' element={<NotAllowed />} />
            <Route path="/" element={<Home />} />
            <Route path="/organizations" element={<Dashboard />} />

            <Route path="/organizations/:id" element={<ViewOrgDetail />} >
              <Route index element={<ViewOrgDetail />} />
              <Route path="resume" />
              <Route path="legal" />
              <Route path="certificados" />
              <Route path="facturas" />
              <Route path="facturas/create" />
              <Route path="facturas/:invoice_id" />
              <Route path="clientes" />
              <Route path="clientes/:client_id" />
              <Route path="productos" />
              <Route path="productos/:product_id" />
            </Route>
        </Routes>
        <Footer />
    </>
  )
}