import React, { Fragment } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, TextField, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon    from '@mui/icons-material/Close';
import * as LottiePlayer from "@lottiefiles/lottie-player";
import Grid from '@mui/material/Grid';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import axios from '../Axios/Axios'
import { useParams, useNavigate } from 'react-router-dom';

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({
    name: yup.string().min(5).required()
});


export default function AddOrganization() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [confirm , setConfirm] = React.useState(false);
    const { id } = useParams(); // Unpacking and retrieve id
    const navigate = useNavigate();


    const { register, handleSubmit, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
      });
    
    const onSubmit = async (data) => {
        setIsLoading(true);
        
        await axios.post('/organizations' , data )
        .then((respon) => {
            setIsLoading(false);
            setConfirm(true);
            setTimeout(() => {
                handleClose();
                navigate(`/organizations/${respon.data.id}/resume`);
            }, 3000);
        })
        .catch((error) =>{
            alert(error);
        })
        setIsLoading(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

  return (
    <Fragment>
        <Button color="primary" variant="contained" startIcon={<AddIcon />} onClick={handleClickOpen} size={"small"} >Crear</Button>
        <Dialog
            fullWidth={true}
            maxWidth={ confirm ? "sm" : "md" }
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogContent>
            <DialogContentText>
                <Grid container spacing={1}>
                { !confirm ?
                <>
                    <Grid item xs={6} sx={{background: "#FAFAFA"}}>
                    <h2 style={{marginLeft: 4, color: "#000"}}>Agregar organización</h2>
                    <Typography variant="body1" color="text.secondary" style={{marginTop:-20, marginLeft: 4}}>
                        Necesitamos conocer datos básicos para poder configurar tu nueva organización.
                    </Typography>
                    <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src="https://assets5.lottiefiles.com/private_files/lf30_n9wqoo7g.json"
                            style={{ width: "300px", height: "300px" }}
                    />
                    </Grid>
                    
                    <Grid xs={6} sx={{marginTop: '25vh'}}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            id="standard-helperText"
                            label="Nombre *"
                            helperText={errors.name ? "Capture el nombre de la organización. " + errors.name.message : ""} 
                            //required
                            //defaultValue={/*item.legal.name*/""}
                            variant="standard"
                            sx={{width: '100%' }}
                            placeholder={"Nombre comercial"}
                            error={errors.name}
                            size="small"
                            {...register("name")}
                        />
                    </form>
                    </Grid>
                </>
                :
                <Grid xs={12}>
                    <div align="center">
                    <h3 style={{marginLeft: 4, color: "#000"}}>Organización creada</h3>
                    <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src="https://assets3.lottiefiles.com/packages/lf20_hFmz66beuw.json"
                            style={{ width: "250px", height: "300px" }}
                    />
                    <br />
                    <Typography variant="body1" color="text.secondary" style={{marginTop:0, marginLeft: 4}}>
                        La organización <b>{getValues("name")}</b> ha sido creada correctamente.
                    </Typography>
                    <br />
                    </div>
                </Grid>
                }
                </Grid>
            </DialogContentText>
            </DialogContent>
            { !confirm ?
            <DialogActions>
            <Button autoFocus onClick={handleClose} variant="contained" color="inherit">
                Cancelar
            </Button>
            <Button 
                onClick={handleSubmit(onSubmit)} 
                autoFocus variant="contained" 
                color="primary" 
                startIcon={ isLoading ? <CircularProgress size={20} color={"inherit"} /> : <AddIcon />}
                >
                Agregar
            </Button>
            </DialogActions>
            : "" }
        </Dialog>
    </Fragment>
  )
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
