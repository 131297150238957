import React from 'react';
import Typography from '@mui/material/Typography';
import { Button, Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link as MuiLink } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import LegalForm from '../../components/Legal/LegalForm';

function Legal(props) {
  return (
    <div style={{marginTop: -80}}>
      <MuiLink to={`/organizations/`} component={RouterLink} underline="none" >
        <Button size='small' startIcon={<ArrowBackIcon />}> Back to Organizations</Button>
      </MuiLink>
        
        <h2>Legal</h2>
        <Typography variant="body1" color="text.secondary" style={{marginTop:-20}}>
          En este apartado podrás gestionar la información fiscal de la organzación. 
          <Divider />
        </Typography>
        <br />
        <LegalForm data={props.data} />
    </div>
  )
}

export default Legal;