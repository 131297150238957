import React from 'react';
import { useForm } from "react-hook-form";
import axios from '../../../components/Axios/Axios';
import { TextField,IconButton, Button, LinearProgress, CircularProgress , Divider } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import { useParams, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import * as LottiePlayer from "@lottiefiles/lottie-player";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

//https://react-hook-form.com/get-started#Quickstart

//file size
//https://timetoprogram.com/validate-file-size-in-react/

export default function UploadFiles () {
  const { id } = useParams(); // Unpacking and retrieve id
  const [isLoading, setIsLoading] = React.useState(false);
  const [cer, setCer] = React.useState(null);
  const [key, setKey] = React.useState(null);
  const [pass, setPass] = React.useState(null);
  const [message,setMessage] = React.useState(null);
  const navigate = useNavigate();

  //form elements
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const onSubmit = async (data) => { 
	setIsLoading(true);
	const formData = new FormData();
	formData.append('cer',cer);
	formData.append('key',key);
	formData.append('password',data.password);
		
	const response = await axios.post(`/certificates/upload/${id}` , formData).then((response) => {
		setMessage(JSON.stringify(response.data) !== '{}' ? "El certificado se proceso corretamente" : "Ocurrió un error al procesar el certificado");
		setIsLoading(false);
	}).catch((error) => {
		setMessage("Ocurrió un error.");
		setIsLoading(false);
	});
	
   };
	

  const handleChange = (event) => {
    if (event.target.files.length > 0) {
		let filename = event.target.files[0];
		setCer(filename);
	}
  }

  const handleChangeKey = (event) => {
    if (event.target.files.length > 0) {
		let filename = event.target.files[0];
		setKey(filename);
	}
  }

  const handleChangePass = (event) => {
    if (event.target.value > 0 ) {
		setPass(event.target.value);
	}
  }

  const handleClose = () => {
    setIsLoading(false);
	//navigate(`/organizations/${id}/resume`, { replace: true });
	navigate(`/organizations`, { replace: true });

  }

  return (
	<div align="center">
		{ isLoading ? /*<LinearProgress />*/ "" : message === null ? "" : <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={true}
              onClose={handleClose}
              autoHideDuration={2000}
              message={message}
            ></Snackbar>
		}

		<Grid container spacing={0}>
        <Grid item xs={6} sx={{background: "#FAFAFA"}}>
          <h2>Cargar certificado</h2>
		  <br />
		  <Typography variant="body1" color="text.secondary" style={{marginTop:-20}}>
			Aquí podrás cargar certificados asociados a tu organización, mismos que podrás utilizar para el proceso de timbrado.
			</Typography>
			<lottie-player
				autoplay
				loop
				mode="normal"
				src="https://assets6.lottiefiles.com/packages/lf20_2oranrew.json"
				style={{ width: "250px", height: "250px" }}
				></lottie-player>
        </Grid>
        <Grid item xs={6}
		container
		spacing={0}
		direction="column"
		alignItems="center"
		justifyContent="center"
		style={{ minHeight: '50vh' }}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
			<TextField
				value={cer ? cer.name : "Seleccione certificado"}
				label="Certificado de Sello Digital (.cer)"
				disabled={cer ? false : true }
				sx={{ m: 1, width: '40ch' }}
				InputProps={{
					fullWidth: true,
					startAdornment: (
						<IconButton component="label">
							<AttachFileIcon />
							<input
								type="file"
								hidden
								onChange={handleChange}
								//name="[name]"
								accept=".cer"
							/>
						</IconButton>
					)
				}}
				{...register("cer", { required: true })}
				error={ errors.cer ? true: false}
			/>
			<br />
			<TextField
				value={key ? key.name : "Seleccione llave"}
				label="Llave privada del certificado (.key)"
				disabled={key ? false : true }
				sx={{ m: 1, width: '40ch' }}
				InputProps={{
					fullWidth: true,
					startAdornment: (
						<IconButton component="label">
							<AttachFileIcon />
							<input
								type="file"
								hidden
								onChange={handleChangeKey}
								//name="[name]"
								accept=".key"
							/>
						</IconButton>
					)
				}}
				{...register("key", { required: true })}
				error={ errors.key ? true: false}
			/>
			<br />
			<TextField
				value={pass}
				label="Contraseña"
				//name="password"
				sx={{ m: 1, width: '40ch' }}
				type="password"
				onChange={handleChangePass}
				{...register("password", { required: true })}
				error={ errors.password ? true: false}
			/>
			
			<br /><br /><br />
			{/*
			<Button variant="outlined" onClick={() => reset()} startIcon={<CleaningServicesIcon />} disabled={isLoading}>Limpiar</Button>&nbsp;
			<Button type='submit'  variant="contained" color="primary" startIcon={isLoading ? <CircularProgress size={20} color={"inherit"} /> : <CloudUploadIcon />}>Guardar</Button>
			
			<Button autoFocus onClick={handleClose} variant="contained" color="inherit" >
            Cancelar
          </Button>*/}&nbsp;&nbsp;
          <Button 
		  	type='submit'
            autoFocus variant="contained" 
            color="primary" 
            startIcon={ isLoading ? <CircularProgress size={20} color={"inherit"} /> : <CloudUploadIcon />}
            >
            Cargar Certificado
          </Button>
			</form>
        </Grid>
      </Grid>
	</div>
	)
}