import React from 'react';
import axios from '../../components/Axios/Axios';
import Typography from '@mui/material/Typography';
import { Divider, LinearProgress } from '@mui/material';
import { Link as MuiLink } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams } from 'react-router-dom';
import ListCertificates from '../../components/certificates/list';
import AddCertificate from '../../components/certificates/add/add';

function Certificates() {
  const [isLoading, setIsLoading] = React.useState(true);
  const shouldLog = React.useRef(true);
  const [data , setData] = React.useState(null);
  const { id } = useParams(); // Unpacking and retrieve id

  const getData = async () => {
    await axios.get("/certificates/" + id)
    .then((response) =>{
      setData(response.data);
      setIsLoading(false);
    }).catch((error) =>{
      alert(error.message);
    });
  }

  React.useEffect( ()=> {
    if(shouldLog.current){
      shouldLog.current = false;
      getData();
    }
  },[data]);

  return (
    <div style={{marginTop: -80}}>
      <MuiLink to={`/organizations/`} component={RouterLink} underline="none" >
        <Button size='small' startIcon={<ArrowBackIcon />}> Back to Organizations</Button>
      </MuiLink>

        <h2>Certificados</h2>
        <Typography variant="body1" color="text.secondary" style={{marginTop:-20}}>
          Aquí podrás cargar certificados para posteriormente utilizarlos en los diferentes procesos de timbrado.
          <Divider />
        </Typography>
        <br />
        <AddCertificate />
        {/*
        <br />
        <UploadFiles />
        */}
        <br />
        { isLoading ? <LinearProgress /> : "" }
        { data !== null ? <ListCertificates certificados={data} /> : "cargando..."}
       
    </div>
  )
}

export default Certificates;