import React, { Fragment } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, TextField, IconButton, Typography, Divider, Alert, Snackbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import * as LottiePlayer from "@lottiefiles/lottie-player";
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import axios from '../Axios/Axios'
import { useParams, useNavigate } from 'react-router-dom';


export default function DelClient(props) {
    const [isLoading, setIsLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [confirm , setConfirm] = React.useState(false);
    const { client_id , id } = useParams(); // Unpacking and retrieve id
    const navigate = useNavigate();
    
    const confirmDelete = async () => {
        setIsLoading(true);
       
        
        //try to delete the client
        await axios.delete(`/clients/${client_id}`)
        .then(() => {
            setIsLoading(false);
            setConfirm(true);
            setTimeout(() => {
                handleClose();
                navigate(`/organizations/${id}/clientes`, { replace: true });
            }, 3000);              
        })
        .catch((error) =>{
            alert(error);
        });
        
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseConfirm = () => {
        setConfirm(false);
    };

  return (
    <Fragment>
        <Button color="error" onClick={handleClickOpen} variant="contained" >Eliminar</Button>
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogContent>
            <DialogContentText>
                <Grid container spacing={1}>
                    <Grid item xs={12} sx={{/*background: "#FAFAFA"*/}}>
                    { !confirm ?
                    <>
                    <h3 style={{marginLeft: 4, color: "#000"}}>Eliminar cliente</h3>
                    <Typography variant="body1" color="text.secondary" style={{marginTop:-10, marginLeft: 4}}>
                        Esta acción eliminará permanentemente al cliente <b>{props.legal_name}</b>. Por favor confirme que realmente desea realizar esta acción.
                    </Typography>
                    </>
                    : 
                    <div align="center">
                    <h3 style={{marginLeft: 4, color: "#000"}}>Cliente eliminado</h3>
                    <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src="https://assets4.lottiefiles.com/packages/lf20_vvrfedzr.json"
                            style={{ width: "200px", height: "200px" }}
                    />
                    <br />
                    <Typography variant="body1" color="text.secondary" style={{marginTop:0, marginLeft: 4}}>
                        El cliente <b>{props.legal_name}</b> ha sido eliminado correctamente.
                    </Typography>
                    <br />
                    </div>
                    }
                    </Grid>
                </Grid>
            </DialogContentText>
            </DialogContent>
            { !confirm ?
            <DialogActions>
            <Button autoFocus onClick={handleClose} variant="contained" color="inherit">
                Cancelar
            </Button>
            <Button 
                onClick={confirmDelete} 
                autoFocus variant="contained" 
                color="error" 
                startIcon={ isLoading ? <CircularProgress size={20} color={"inherit"} /> : ""}
                >
                Eliminar
            </Button>
            </DialogActions>
            : "" }
        </Dialog>
    </Fragment>
  )
}
