import React from 'react';
import Typography from '@mui/material/Typography';
import { Divider, LinearProgress } from '@mui/material';
import { Link as MuiLink } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuth0 } from '@auth0/auth0-react';
import axios from '../../components/Axios/Axios';
import { useParams , useNavigate} from 'react-router-dom';
import InvoiceList from '../../components/Invoices/InvoiceList';


function Facturas() {
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = React.useState(true);
  const shouldLog = React.useRef(true);
  const { id } = useParams(); // Unpacking and retrieve id
  const [data , setData] = React.useState([]);
  const navigate = useNavigate();

  const getData = async () => {
    const params = { org_id : id }
    const token = await getAccessTokenSilently();
    axios.defaults.headers['Authorization'] = `Bearer ${token}`;
    await axios.get("/invoices" , { params: params } )
    .then(response =>{
      setData(response.data);
      setIsLoading(false);
    })
    .catch((error) => {
      navigate("/notallowed");
      console.error(error);
    });
  }

  React.useEffect( ()=> {
    if(shouldLog.current){
      shouldLog.current = false;
      getData();
    }
  },[]);

  return (
    <div style={{marginTop: -80}}>
      <MuiLink to={`/organizations/`} component={RouterLink} underline="none" >
        <Button size='small' startIcon={<ArrowBackIcon />}> Back to Organizations</Button>
      </MuiLink>
        <h2>Facturas</h2>
        <Typography variant="body1" color="text.secondary" style={{marginTop:-20}}>
          Aquí podrás consultar el listado de facturas.
          <Divider />
        </Typography>
        { isLoading ? <LinearProgress /> : <InvoiceList invoices={data} /> }
    </div>
  )
}

export default Facturas;