import React , { Fragment } from 'react';
import * as LottiePlayer from "@lottiefiles/lottie-player";
import { Dialog, DialogContent, DialogContentText , Divider, Grid, Box,  Typography, DialogActions, Button } from "@mui/material";
import { useParams, useNavigate } from 'react-router-dom';

export default function ConfirmSuscription(props) {
  let [open, setOpen] = React.useState(true);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate(`/organizations/`);
  };

  return (
    <Fragment>
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogContent>
            <DialogContentText>
                <Grid container spacing={1}>
                    <Grid item xs={12} sx={{/*background: "#FAFAFA"*/}}>
                    <div align="center">
                    <h2 style={{marginLeft: 4, color: "#000"}}>Confirmación de suscripción</h2>
                    <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src="https://assets2.lottiefiles.com/packages/lf20_5zYhWw.json"
                            style={{ width: "300px", height: "300px" }}
                    />
                    <br />
                    <Typography variant="body1" color="text.secondary" style={{marginTop:0, marginLeft: 4}}>
                        En horabuena, tu suscripción ha sido confirmada correctamente. <br /> Comienza a emitir facturas ahora mismo.
                    </Typography>
                    <br />
                    <DialogActions>
                      <Button variant='outlined' onClick={handleClose}>Continuar</Button>
                    </DialogActions>
                    </div>
                    </Grid>
                </Grid>
            </DialogContentText>
            </DialogContent>
        </Dialog>
      </Fragment>
  )
}
