import React, { useState } from 'react';
import Sidebar from '../../components/template/Sidebar';
import getOrgDetail from '../../services/getOrgDetail';

export default function ViewOrgDetail() {
  //call the hook with data
  const [data] = getOrgDetail();

  return (
    <div>
      { data.length === 0  ? "" : <Sidebar data={data} /> }
    </div>
  )
}
