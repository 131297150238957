import React from 'react'
import { Link } from 'react-router-dom';
import * as LottiePlayer from "@lottiefiles/lottie-player";
import { Button, Typography } from '@mui/material';

export default function NotAllowed() {
  return (
    <div style={{
        position: 'absolute',
        left: '50%',
        top: '45%',
        transform: 'translate(-50%, -50%)',
        align: 'center'
    }}>
        <div align="center">
            <div style={{marginTop: 50}}>
            <h3 style={{marginLeft: 4, color: "#000" }}>Acción no permitida</h3>
            <Typography variant="body1" color="text.secondary" style={{marginTop:0, marginLeft: 4}}>
                No cuenta con los permisos suficientes para realizar la acción solicitada.
            </Typography>  
            </div>
            <div style={{marginTop: -100}}>      
                <lottie-player
                    autoplay
                    loop
                    mode="normal"
                    src="https://assets10.lottiefiles.com/packages/lf20_scqggbnw.json"
                    style={{ width: "400px", height: "400px" }}
                />
                <Link to="/organizations">
                    <Button color='primary' variant='contained'>Continuar</Button>
                </Link>
            </div>
        </div>
    </div>
  )
}


//https://assets10.lottiefiles.com/packages/lf20_tmsiddoc.json