import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { useAuth0 } from "@auth0/auth0-react";


export default function Suscription() {
  const { user, isAuthenticated, isLoading , getIdTokenClaims } = useAuth0();
  const [open, setOpen] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState(false);
  const [claims , setClaims ] = React.useState(getIdTokenClaims());

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen} >
        Suscríbete a un plan
      </Button> &nbsp;&nbsp;
      <Dialog
        fullWidth
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle><h3>Suscríbete a un plan</h3></DialogTitle>
        <DialogContent>   
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              { isAuthenticated && (
              <stripe-pricing-table 
                pricing-table-id="prctbl_1NC0bmBvJziba8rDlJO14KC1" 
                publishable-key="pk_test_51Mvu4MBvJziba8rDw41eHjENYr4EWIARyWrXQIY2ffj1CtDRVjwWikgikKSBqQDzmawAA34JRQVsWLEDC4iqPMF200VtVvhLlh"
                customer-email={user.email}
                >
              </stripe-pricing-table>
              )}  
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}