import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_URL + "/api/v1"
});

const responseInterceptor = function (response) {
    return response;
};

const errorInterceptor = function (error) {
    if (error.isAxiosError) {
      return Promise.reject(new Error(error.response.data.message));
    } else {
      return Promise.reject(new Error(error.message));
    }
};

instance.interceptors.response.use(
    responseInterceptor,
    errorInterceptor
);

export default instance;