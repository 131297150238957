import React from 'react';
import axios from '../components/Axios/Axios';
import { useAuth0 } from '@auth0/auth0-react';

// GetAllOrgs.js
const GetAllOrgs = () => {
    const [organizations , setOrganizations] = React.useState([]);
    const shouldLog = React.useRef(true);
    const { getAccessTokenSilently } = useAuth0();
    // 1. Prepare the request
    const getOrganizations = async () => {
      const token = await getAccessTokenSilently();
      axios.defaults.headers['Authorization'] = `Bearer ${token}`;
      await axios.get(`/organizations/`)
      .then((response) =>{
        setOrganizations(response.data);
        //setIsLoading(false);
      }).catch((error) =>{
        console.error(error.message);
      });
    }

    // 2. Run the requesr
    React.useEffect(() => {
        if(shouldLog.current){
            getOrganizations();
            shouldLog.current = false;
        }
    
      }, []);

      // 3. Return the values
      return [organizations];
};


export default GetAllOrgs;
