import React, { useState } from 'react';
import { QRCode } from 'react-qrcode-logo';

export default function LectorQR(props) {
  return (
    <>
      <QRCode value={props.qrCode} size="150" logoImage="/images/logo.png" logoOpacity="1" />
    </>
  )
}
