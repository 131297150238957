import React from 'react';
import { Avatar, Button, CardHeader, Divider, IconButton, Typography } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ViewProduct from '../Products/ViewProduct';
import ProductForm from './ProductForm';

export default function ReadProduct(props) { 
  const product = props.product;
  return (
    <div style={{marginTop: -50}}>
       <CardHeader sx={{height:175}}
          avatar={
              <Avatar 
                sx={{ bgcolor: product.logo_url !== null ? "#051e34" : "#051e34" , width: 48, height: 48 }} 
                aria-label="recipe" 
                src={ product.logo_url !== null ? product.logo_url : ""}
                variant="rounded" >
                <ShoppingCartIcon />
              </Avatar>
          }
          title={
          <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
            {product.product_key}
            <Divider />
          </Typography>
        }
          subheader={product.description}
        />
        <ProductForm product={props.product} />
    </div>
  )
}
