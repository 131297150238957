import React, { Component } from "react";
import ReactTextTransition, { presets } from "react-text-transition";

const randomNumber = () => Math.floor(Math.random() * 9999999999 + 100000);

const texts = ["confiable", "segura", "rápida", "fácil"];
const texts2 = ["negocio", "emprendimiento", "empresa", "PyME"];

class TextAnimation extends Component {
  state = {
    number: randomNumber(),
    textIndex: 0,
    textFastIndex: 0,
    paragraphIndex: 0,
    customIndex: 0
  };

  componentDidMount() {
    setInterval(() => {
      this.setState({
        number: randomNumber(),
        textIndex: this.state.textIndex + 1,
        paragraphIndex: this.state.paragraphIndex + 1
      });
    }, 4000);
    setInterval(() => {
      this.setState({
        textFastIndex: this.state.textFastIndex + 1
      });
    }, 150);
  }

  render() {
    return (
      <React.Fragment>
        <section>
          <section className="inline">
            La plataforma de facturación<br/>
            <ReactTextTransition 
              springConfig={presets.gentle}
              style={{ margin: "0 4px",color:"rgb(25, 118, 210)", fontWeight:"bold" , fontFamily:"Lato" }}
              inline
            >
              {texts[this.state.textIndex % texts.length]}
            </ReactTextTransition>
            para su <b style={{color:"rgb(25, 118, 210)",fontFamily:"Lato" }}>{texts2[this.state.textIndex % texts.length]}</b>
          </section>
        </section>
      </React.Fragment>
    );
  }
}

export default TextAnimation;
