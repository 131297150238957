import React, { Fragment } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, TextField, IconButton, Typography, Divider, Alert, Snackbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import * as LottiePlayer from "@lottiefiles/lottie-player";
import Grid from '@mui/material/Grid';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import axios from '../Axios/Axios'

// form
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from 'react-router-dom';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { alignProperty } from '@mui/material/styles/cssUtils';

const schema = yup.object({
    legal_name: yup.string().min(5).required(),
    tax_id: yup.string().min(12).max(13).required(),
    zip: yup.string().required(),
});


export default function AddClient() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [confirm , setConfirm] = React.useState(false);
    const { id } = useParams(); // Unpacking and retrieve id
    const navigate = useNavigate();

    const { register, handleSubmit, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
      });
    
    const onSubmit = async (data) => {
        setIsLoading(true);
        //try to save the info
        data.org_id = id;
        await axios.post('/clients' , data )
        .then((respon) => {
            setIsLoading(false);
            setConfirm(true);
            setTimeout(() => {
                handleClose();
                navigate(`/organizations/${id}/clientes/${respon.data.id}`);
            }, 3000);     
        })
        .catch((error) =>{
            alert(error);
        })
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseConfirm = () => {
        setConfirm(false);
    };

  return (
    <Fragment>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClickOpen} variant="contained">Agregar</Button>
        <Dialog
            fullWidth={true}
            maxWidth={ confirm ? "sm" : "md" }
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogContent>
            <DialogContentText>
                <Grid container spacing={1}>
                { !confirm ?
                <>
                    <Grid item xs={6} sx={{background: "#FAFAFA"}}>
                    <h2 style={{marginLeft: 4, color: "#000"}}>Agregar cliente</h2>
                    <Typography variant="body1" color="text.secondary" style={{marginTop:-20, marginLeft: 4}}>
                        Configura el perfil de tu nuevo cliente con la siguiente información.
                    </Typography>
                    <div align="center">
                    <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src="https://assets9.lottiefiles.com/private_files/lf30_ft0cqvpu.json"
                            style={{ width: "300px", height: "300px" }}
                    />
                    <Typography variant="body2" color="text.secondary" style={{marginTop:-20}}>
                        <InfoIcon color='primary'/> Asegúrate que la información que captures coincida tal como aparece en la <b>constancia
                        de situación fiscal</b> emitida por el SAT.
                    </Typography>
                    </div>
                    </Grid>
                    
                    <Grid xs={6} sx={{marginTop: '15vh'}}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            id="standard-helperText"
                            label="Nombre / Razón social *"
                            helperText="Capture el nombre o razón social de su nuevo cliente."
                            variant="standard"
                            sx={{width: '100%' }}
                            placeholder={"Grupo Financiero Inbursa"}
                            error={errors.legal_name}
                            size="small"
                            {...register("legal_name")}
                        />
                        <br /><br />
                        <TextField
                            id="standard-helperText"
                            label="RFC *"
                            helperText="Capture el rfc de su nuevo cliente."
                            variant="standard"
                            sx={{width: '100%' }}
                            placeholder={"ZFD900812XYZ"}
                            error={errors.tax_id}
                            size="small"
                            {...register("tax_id")}
                        />
                        <br /><br />
                        <TextField
                            id="standard-helperText"
                            label="Código postal *"
                            helperText="Capture el código postal de su nuevo cliente."
                            variant="standard"
                            sx={{width: '100%' }}
                            placeholder={"06300"}
                            error={errors.zip}
                            size="small"
                            {...register("zip")}
                        />
                    </form>
                    </Grid>
                </>
                : 
                <Grid xs={12}>
                    <div align="center">
                    <h3 style={{marginLeft: 4, color: "#000"}}>Cliente creado</h3>
                    <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src="https://assets3.lottiefiles.com/packages/lf20_hFmz66beuw.json"
                            style={{ width: "250px", height: "300px" }}
                    />
                    <br />
                    <Typography variant="body1" color="text.secondary" style={{marginTop:0, marginLeft: 4}}>
                        El cliente <b>{getValues("legal_name")}</b> ha sido creado correctamente.
                    </Typography>
                    <br />
                    </div>
                </Grid>
                }
                </Grid>
            </DialogContentText>
            </DialogContent>
            { !confirm ?
            <DialogActions>
            <Button autoFocus onClick={handleClose} variant="contained" color="inherit">
                Cancelar
            </Button>
            <Button 
                onClick={handleSubmit(onSubmit)} 
                autoFocus variant="contained" 
                color="primary" 
                startIcon={ isLoading ? <CircularProgress size={20} color={"inherit"} /> : <AddIcon />}
                >
                Agregar
            </Button>
            </DialogActions>
            : "" }
        </Dialog>
    </Fragment>
  )
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
