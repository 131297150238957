import React from 'react';
import GetAllOrgs from '../../services/getAllOrgs';
import Organizations from '../Organizations/Index';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

export default function Dashboard() {
  //const [data,setData] = React.useState(dataOrg.data);
  const [data] = GetAllOrgs();
  return (
    <div style={{ marginLeft: 16, marginRight: 16}}>
          { data.length !== 0 ? <Organizations data={data} /> : 
            
            <Box sx={{ flexGrow: 1 }}>
              <br />
              <LinearProgress />
              <br />
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <SkeletonLoader />
                </Grid>
                <Grid item xs={3}>
                <SkeletonLoader />
                </Grid>
              </Grid>
            </Box>
            
          }
    </div>
  )
}


function SkeletonLoader () {
  return (
    <Stack spacing={1}>
      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="circular" width={40} height={40} animation="wave" />
      <Skeleton variant="rectangular" height={60} animation="wave"/>
      <Skeleton variant="rounded"  height={60} animation="wave"/>
    </Stack>
  )
}