import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <>
      <Button color="inherit" /*startIcon={<LoginIcon />}*/ onClick={() => loginWithRedirect()}>Iniciar sesión</Button>
    </>
  )
};

export default Login;