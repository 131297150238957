import {Link} from "react-router-dom";

import React from 'react'

function NotFound() {
  return (
    <div style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    }}>
        <h3>404 page not found</h3>
        <p>We are sorry but the page you are looking for does not exist.</p>
        <Link to="/">Inicio</Link>
    </div>
  )
}

export default NotFound