import React from 'react';
import { Avatar, Button, CardHeader, Divider, IconButton, Typography } from '@mui/material';
import ClientForm from './ClientForm';

export default function ViewClient(props) { 
  const client = props.client;
  return (
    <div style={{marginTop: -50}}>
       <CardHeader sx={{height:175}}
          avatar={
              <Avatar 
                sx={{ bgcolor: client.logo_url !== null ? "#fefefe" : "#051e34" , width: 48, height: 48 }} 
                aria-label="recipe" 
                src={ client.logo_url !== null ? client.logo_url : ""}
                variant="rounded" >
                {/*client.legal_name.charAt(0).toUpperCase()*/}
              </Avatar>
          }
          title={
          <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
            {client.legal_name}
            <Divider />
          </Typography>
        }
          subheader={client.tax_id}
        />
        <ClientForm client={props.client} />
    </div>
  )
}
