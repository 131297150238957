import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import { Button, TextField, Grid, Typography, Divider, Paper, InputLabel, Alert, Snackbar } from "@mui/material";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from '../Axios/Axios'
import { useParams, useNavigate } from 'react-router-dom';

import "./styles.css";

const schema = yup.object({
    name: yup.string().required(),
    legal_name: yup.string().min(1).max(30).required(),
    tax_system: yup.string().required(),
    correo: yup.string().email(),
    zip: yup.string(5).matches(/^[0-9]{5}/).required(),
});

//tay system cat
const catRegimenes = [
    {
        value: '601',
        label: 'General de ley Personas Morales',
    },
    {
        value: '611',
        label: 'Régimen de Arrendamiento',
    }
];



export default function LegalForm(props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [confirm , setConfirm] = React.useState(false);
  const { id } = useParams(); // Unpacking and retrieve id
  const navigate = useNavigate();

  const  item = props.data;
  
  const { register, handleSubmit, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const updateData = {
        legal: {
            name: data.name,
            tax_id: data.tax_id,
            legal_name: data.legal_name,
            tax_system: data.tax_system,
            email: data.email,
            website: data.website,
            phone: data.phone,
            address: {
                street: data.street,
                exterior: data.exterior,
                interior: data.interior,
                neighborhood: data.neighborhood,
                zip: data.zip,
                municipality: data.municipality,
                state: data.state
            },
        },
        "pending_steps.0.is_ready" : true,
        logo_url: data.logo_url,
        org_id: id
    }
    handleOpen();
    setIsLoading(true);
    data.org_id = id;
    await axios.patch('/organizations' , updateData )
    .then((repon) => {
        setIsLoading(false);
        setConfirm(true);
        setTimeout(() => {
            handleClose();
            //navigate(`/organizations/${id}/resume`);
            navigate(`/organizations/`);
        }, 3000);
    })
    .catch((error) =>{
        alert(error);
    })
    setIsLoading(false);
    
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
        <Fragment>
        <Grid container spacing={0}>
            <Grid xs={6} > 
                <h3>Identificación fiscal</h3> 
                <Grid xs={12}> 
                <TextField
                    id="standard-helperText"
                    label="Nombre comercial *"
                    defaultValue={item.legal.name}
                    variant="filled"
                    sx={{width: '45%' }}
                    placeholder={"Nombre comercial"}
                    error={errors.name}
                    size="small"
                    {...register("name")}
                />
                &nbsp;
                <TextField
                id="standard-helperText"
                label="Nombre / Razón social *"
                defaultValue={ item.legal.legal_name }
                variant="filled"
                sx={{width: '45%' }}
                placeholder={"Mi empresa"}
                error={errors.legal_name} 
                size="small"
                {...register("legal_name")}
                />
                </Grid>
                <br />
                <Grid xs={12}>
                <TextField
                    id="standard-helperText"
                    label="RFC *"
                    defaultValue={item.legal.tax_id}
                    variant="filled"
                    sx={{width: '45%' }}
                    placeholder={"XYZ210415UZ2"}
                    error={errors.tax_id}
                    size="small"
                    InputProps={{
                        readOnly: true
                    }}
                    {...register("tax_id")}
                />&nbsp;
                {/*
                <TextField
                    id="standard-helperText"
                    label="Régimen fiscal *"
                    defaultValue={item.legal.tax_system}
                    variant="filled"
                    sx={{width: '45%' }}
                    placeholder={"Régimen fiscal"}
                    error={errors.regimen_fiscal}
                    size="small"
                    {...register("regimen_fiscal")}
                />*/}
                <TextField
                    id="filled-select-currency-native"
                    select
                    label="Régimen fiscal *"
                    size="small"
                    sx={{width: '45%' }}
                    error={errors.tax_system}
                    defaultValue={item.legal.tax_system}
                    //value={currency}
                    //onChange={handleChange}
                    SelectProps={{
                        native: true,
                    }}
                    //helperText="Please select your currency"
                    variant="filled"
                    {...register("tax_system")}
                    >
                    {catRegimenes.map((option) => (
                        <option 
                        key={option.value} value={ option.value } 
                        selected={ item.legal.tax_system === option.value ? true : false } 
                        >
                        { option.label }
                        </option>
                    ))}
                </TextField>

                </Grid> 
                <h3>Información de contacto</h3> 
                <Grid xs={12}> 
                <TextField
                id="standard-helperText"
                label="Correo electrónico"
                defaultValue={item.legal.email}
                variant="filled"
                sx={{width: '45%' }}
                placeholder={"contacto@dominio.com"}
                error={errors.email}
                size="small"
                {...register("email")}
                />
                &nbsp;
                <TextField
                id="standard-helperText"
                label="Teléfono"
                defaultValue={item.legal.phone}
                variant="filled"
                sx={{width: '45%' }}
                placeholder={"5560298756"}
                error={errors.phone} 
                size="small"
                {...register("phone")}
                />
                </Grid>
                <br />
                <Grid xs={12}>
                <TextField
                    id="standard-helperText"
                    label="Sitio web"
                    defaultValue={item.legal.website}
                    helperText={errors.firstName && errors.firstName.message}
                    variant="filled"
                    sx={{width: '45%' }}
                    placeholder={"https://dominio.com"}
                    error={errors.website}
                    size="small"
                    {...register("website")}
                />&nbsp;
                <TextField
                    id="standard-helperText"
                    label="Logotipo"
                    defaultValue={item.logo_url}
                    variant="filled"
                    sx={{width: '45%' }}
                    placeholder={"https://mi_logo.png"}
                    error={errors.logo_url} 
                    size="small"
                    {...register("logo_url")}
                />
                </Grid> 
            </Grid>
            <Grid xs={6}> 
            <h3>Domicilio fiscal</h3>
            <Grid xs={12}> 
                <TextField
                    id="standard-helperText"
                    label="Calle"
                    defaultValue={item.legal.address.street}
                    variant="filled"
                    sx={{width: '50%' }}
                    placeholder={"Mariano Matamoros"}
                    error={errors.street}
                    size="small"
                    {...register("street")}
                />
                &nbsp;
                <TextField
                id="standard-helperText"
                label="Número exterior"
                defaultValue={ item.legal.address.exterior }
                variant="filled"
                sx={{width: '24%' }}
                placeholder={"19"}
                error={errors.exterior} 
                size="small"
                {...register("exterior")}
                />
                &nbsp;
                <TextField
                id="standard-helperText"
                label="Número interior"
                defaultValue={ item.legal.address.interior }
                variant="filled"
                sx={{width: '24%' }}
                placeholder={"19"}
                error={errors.interior} 
                size="small"
                {...register("interior")}
                />
                </Grid>
                <br />
                <Grid xs={12}>
                <TextField
                    id="standard-helperText"
                    label="Código postal *"
                    defaultValue={item.legal.address.zip}
                    variant="filled"
                    sx={{width: '45%' }}
                    placeholder={"55540"}
                    error={errors.zip}
                    size="small"
                    {...register("zip")}
                />&nbsp;
                <TextField
                    id="standard-helperText"
                    label="Colonia"
                    defaultValue={item.legal.address.neighborhood}
                    variant="filled"
                    sx={{width: '54%' }}
                    placeholder={"Santa Clara Coatitla"}
                    error={errors.neighborhood}
                    size="small"
                    {...register("neighborhood")}
                />
                </Grid>
                <br />
                <Grid xs={12}>
                <TextField
                    id="standard-helperText"
                    label="Ciudad"
                    defaultValue={item.legal.address.municipality}
                    variant="filled"
                    sx={{width: '45%' }}
                    placeholder={"Ciudad de México"}
                    error={errors.municipality}
                    size="small"
                    {...register("municipality")}
                />&nbsp;
                <TextField
                    id="standard-helperText"
                    label="Municipio"
                    defaultValue={item.legal.address.state}
                    variant="filled"
                    sx={{width: '54%' }}
                    placeholder={"Estado de México"}
                    error={errors.state}
                    size="small"
                    {...register("state")}
                />
                </Grid>    
            </Grid>
        </Grid> 
        <br />
        <div align="left">
            <Grid xs={12}>
                <Button type="submit" color="primary" variant="contained" startIcon={<SaveIcon />}>Guardar</Button>
            </Grid>
        </div>
        </Fragment>
        { confirm ?
        <>
        <Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={ confirm ? "sm" : "md" }
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title">
                <DialogContent>
                    <DialogContentText>
                        <Grid container spacing={1}>
                        <Grid xs={12}>
                            <div align="center">
                            <h3 style={{marginLeft: 4, color: "#000"}}>Organización actualizada</h3>
                            <lottie-player
                                    autoplay
                                    loop
                                    mode="normal"
                                    src="https://assets3.lottiefiles.com/packages/lf20_hFmz66beuw.json"
                                    style={{ width: "250px", height: "300px" }}
                            />
                            <br />
                            <Typography variant="body1" color="text.secondary" style={{marginTop:0, marginLeft: 4}}>
                                La organización <b>{getValues("name")}</b> ha sido actualizada correctamente.
                            </Typography>
                            <br />
                            </div>
                        </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Fragment>
        </>
            : ""
        }
    </form>
  );
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    justifyItems: 'center',
    color: theme.palette.text.secondary,
  }));