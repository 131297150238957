import React, { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import { Avatar, Button, CardHeader, Divider, ListItemButton, ListItemAvatar, ListItem, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link as MuiLink } from '@mui/material';
import { Link as RouterLink , useParams } from "react-router-dom";
import PendingSteps from '../../components/Organizations/PendingSteps';
import DeleteOrganization from '../../components/Organizations/DeleteOrganization';
import AdvancedConfiguration from '../../components/Organizations/AdvancedConfiguration';


function Resume(props) {
  const  item = props.data;
  return (
    <div style={{marginTop: -80}}>
        <Fragment>
          <MuiLink to={`/organizations/`} component={RouterLink} underline="none" >
            <Button size='small' startIcon={<ArrowBackIcon />}> Back to Organizations</Button>
          </MuiLink>
          <CardHeader
              avatar={
                <Avatar 
                  sx={{ bgcolor: typeof item.logo_url !== 'undefined' ? "#fefefe" : "#051e34" , width: 48, height: 48 }} 
                  aria-label="recipe" 
                  src={ typeof item.logo_url !== 'undefined' ? item.logo_url : ""}
                  variant="square" >
                  {item.legal.name.charAt(0).toUpperCase()}
                </Avatar>
              }
              title={<Typography variant="h6" style={{fontWeight: 'bold'}}>{item.legal.name}</Typography>}
              subheader={item.legal.tax_id}
            />
            
          <Divider />
          <br />
          {/** pending steps */}
          <PendingSteps steps={item.pending_steps} />
          <Divider />
          {/** advanced configuration */}
          <AdvancedConfiguration item={item} />
          {/** danger zone */}
          <Grid xs={12}>
                <h3>Danger zone</h3>
                    <ListItem
                        sx={{background: "rgb(255, 238, 237)"}}
                        secondaryAction={ <DeleteOrganization item={item} />}
                        disablePadding
                    >
                        <ListItemButton>
                            <ListItemAvatar>
                                <CardHeader sx={{height:75}}
                                title={<Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{"Eliminar organización"}</Typography>}
                                subheader={"Esta acción eliminará permanentemente la organización " + item.legal.name + ". Clic en el botón eliminar para continuar con esta acción."}
                                />
                            </ListItemAvatar>
                        </ListItemButton>
                    </ListItem>
          </Grid>
          <br />
          <Divider />
        </Fragment>
    </div>
  )
}

export default Resume;