import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import { Button, TextField, Grid, Typography, Alert, Snackbar, CardHeader,  ListItemButton, ListItemAvatar, ListItem, Dialog, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams, useNavigate } from 'react-router-dom';
import DelClient from "./DeleteClient";
import axios from '../Axios/Axios';

const schema = yup.object({
    legal_name: yup.string().min(1).max(40).required(),
    tax_id: yup.string().min(12).max(13).required(),
    regimen_fiscal: yup.string().required(),
    correo: yup.string().email(),
    zip: yup.string().required()
});

//tay system cat
const catRegimenes = [
    {
        value: '601',
        label: 'General de ley Personas Morales',
    },
    {
        value: '606',
        label: 'Régimen de Arrendamiento',
    },
    {
        value: '612',
        label: 'Actividades Empresariales y Profesionales',
    },
    {
        value: '626',
        label: 'Régimen Simplificado de Confianza',
    }
];



export default function ClientForm(props) {
  const [open, setOpen] = React.useState(false);
  const { id } = useParams(); // Unpacking and retrieve id
    const navigate = useNavigate();

  const  client = props.client;
  
  const { register, handleSubmit, getValues,formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data) => {
    data.client_id = client._id;
    await axios.put('/clients' , data)
    .then((repon) => {
        setOpen(true);
        setTimeout(() => {
            handleClose();
            navigate(`/organizations/${id}/clientes`, { replace: true });
        }, 3000);
    })
    .catch((error) =>{
        alert(error);
    })
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{marginTop: -50}}>
    <form onSubmit={handleSubmit(onSubmit)}>
        <Fragment>
        <Typography variant="body2" color="text.secondary" style={{marginTop:-10}}>
            <Alert severity="info"> Asegúrate que la información que captures en los campos requeridos (*), coincida con la que aparece en la <b>constancia
            de situación fiscal</b> emitida por el SAT. </Alert>
        </Typography>
        <Grid container spacing={0}>
            <Grid xs={6} > 
                <h3>Identificación fiscal</h3> 
                <Grid xs={12}>
                <TextField
                    id="standard-helperText"
                    label="RFC *"
                    defaultValue={client.tax_id}
                    variant="filled"
                    sx={{width: '45%' }}
                    placeholder={"XYZ210415UZ2"}
                    error={errors.tax_id}
                    size="small"
                    {...register("tax_id")}
                />&nbsp; 
                <TextField
                id="standard-helperText"
                label="Nombre / Razón social *"
                defaultValue={ client.legal_name }
                variant="filled"
                sx={{width: '45%' }}
                placeholder={"Nombre del cliente"}
                error={errors.legal_name} 
                size="small"
                {...register("legal_name")}
                />
                </Grid>
                <br />
                <Grid xs={12}>
                <TextField
                    id="filled-select-currency-native"
                    select
                    label="Régimen fiscal *"
                    size="small"
                    sx={{width: '90%' }}
                    error={errors.regimen_fiscal}
                    defaultValue={client.tax_system}
                    //value={currency}
                    //onChange={handleChange}
                    SelectProps={{
                        native: true,
                    }}
                    //helperText="Please select your currency"
                    variant="filled"
                    {...register("regimen_fiscal")}
                    >
                    {catRegimenes.map((option) => (
                        <option 
                        key={option.value} value={ option.value } 
                        selected={ client.tax_system === option.value ? true : false } 
                        >
                        { option.label }
                        </option>
                    ))}
                </TextField>

                </Grid> 
                <h3>Información de contacto</h3> 
                <Grid xs={12}> 
                <TextField
                id="standard-helperText"
                label="Correo electrónico"
                defaultValue={client.email}
                variant="filled"
                sx={{width: '45%' }}
                placeholder={"contacto@dominio.com"}
                error={errors.correo}
                size="small"
                {...register("correo")}
                />
                &nbsp;
                <TextField
                id="standard-helperText"
                label="Teléfono"
                defaultValue={client.phone}
                variant="filled"
                sx={{width: '45%' }}
                placeholder={"5560298756"}
                error={errors.telefono} 
                size="small"
                {...register("telefono")}
                />
                </Grid>
                <br />
                <Grid xs={12}>
                <TextField
                    id="standard-helperText"
                    label="Custom logo"
                    defaultValue={client?.logo_url}
                    variant="filled"
                    sx={{width: '91%' }}
                    placeholder={"https://milogo.jpg"}
                    error={errors.logo_url}
                    size="small"
                    {...register("logo_url")}
                />
                </Grid>
            </Grid>
            
            <Grid xs={6}> 
                <h3>Domicilio fiscal</h3>
                <Grid xs={12}> 
                    <TextField
                        id="standard-helperText"
                        label="Calle"
                        defaultValue={ client.address?.street }
                        variant="filled"
                        sx={{width: '50%' }}
                        placeholder={"Mariano Matamoros"}
                        error={errors.calle}
                        size="small"
                        {...register("calle")}
                    />
                    &nbsp;
                    <TextField
                    id="standard-helperText"
                    label="Número exterior"
                    defaultValue={ client.address?.exterior }
                    variant="filled"
                    sx={{width: '24%' }}
                    placeholder={"19"}
                    error={errors.exterior} 
                    size="small"
                    {...register("exterior")}
                    />
                    &nbsp;
                    <TextField
                    id="standard-helperText"
                    label="Número interior"
                    defaultValue={ client.address?.interior }
                    variant="filled"
                    sx={{width: '24%' }}
                    placeholder={"19"}
                    error={errors.interior} 
                    size="small"
                    {...register("interior")}
                    />
                   
                </Grid>
                <br />
                
                <Grid xs={12}>
                <TextField
                    id="standard-helperText"
                    label="Código postal *"
                    defaultValue={client.address?.zip}
                    variant="filled"
                    sx={{width: '45%' }}
                    placeholder={"55540"}
                    error={errors.zip}
                    size="small"
                    {...register("zip")}
                />&nbsp;
                <TextField
                    id="standard-helperText"
                    label="Colonia"
                    defaultValue={client.address?.neighborhood}
                    variant="filled"
                    sx={{width: '54%' }}
                    placeholder={"Santa Clara Coatitla"}
                    error={errors.colonia}
                    size="small"
                    {...register("colonia")}
                />
                </Grid>
                <br />
                <Grid xs={12}>
                <TextField
                    id="standard-helperText"
                    label="Ciudad"
                    defaultValue={client.address?.city}
                    variant="filled"
                    sx={{width: '45%' }}
                    placeholder={"Mexico"}
                    error={errors.city}
                    size="small"
                    {...register("city")}
                />&nbsp;
                <TextField
                    id="standard-helperText"
                    label="Estado"
                    defaultValue={client.address?.state}
                    variant="filled"
                    sx={{width: '54%' }}
                    placeholder={"Estado de México"}
                    error={errors.estado}
                    size="small"
                    {...register("state")}
                />
                </Grid>
            </Grid>
        </Grid>
        <br />
        <div align="left">
            <Grid xs={12}>
                <Button type="submit" color="primary" variant="contained" startIcon={<SaveIcon />}>Guardar</Button>
                &nbsp; 
                <Typography variant="caption" color="text.secondary" style={{marginLeft: 4}}>
                    last updated <b>{client.updatedAt}</b>
                </Typography>
            </Grid>
            <br />
            <Grid xs={12}>
                <h3>Danger zone</h3>
                    <ListItem
                        key={client.legal_name}
                        sx={{background: "rgb(255, 238, 237)"}}
                        secondaryAction={ <DelClient legal_name={client.legal_name} />}
                        disablePadding
                    >
                        <ListItemButton>
                            <ListItemAvatar>
                                <CardHeader sx={{height:75}}
                                title={<Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{"Eliminar cliente"}</Typography>}
                                subheader={"Esta acción eliminará permanentemente al cliente " + client.legal_name + ". Clic en el botón eliminar para continuar con esta acción."}
                                />
                            </ListItemAvatar>
                        </ListItemButton>
                    </ListItem>
            </Grid>
        </div>
        </Fragment>
    </form>
    <Fragment>
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogContent>
            <DialogContentText>
                <Grid container spacing={1}>
                    <Grid item xs={12} sx={{/*background: "#FAFAFA"*/}}>
                    <div align="center">
                    <h3 style={{marginLeft: 4, color: "#000"}}>Cliente actualizado</h3>
                    <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src="https://assets3.lottiefiles.com/packages/lf20_hFmz66beuw.json"
                            style={{ width: "200px", height: "200px" }}
                    />
                    <br />
                    <Typography variant="body1" color="text.secondary" style={{marginTop:0, marginLeft: 4}}>
                        El cliente <b>{getValues("legal_name")}</b> ha sido actualizado correctamente. 
                    </Typography>
                    <br />
                    </div>
                    </Grid>
                </Grid>
            </DialogContentText>
            </DialogContent>
        </Dialog>
    </Fragment>
    </div>
  );
}
