import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { IconButton,CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import UploadFiles from '../upload/upload';
import CloseIcon from '@mui/icons-material/Close';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../../components/Axios/Axios';

export default function AddCertificate(props) {
  const { id } = useParams(); // Unpacking and retrieve id
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [data , setData] = React.useState(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <ButtonGroup variant="contained" aria-label="split button">
        <Button onClick={handleClickOpen} startIcon={<VerifiedUserIcon />} >Cargar certificado</Button>
      </ButtonGroup>
        
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton sx={{ ml: 'auto' }} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <UploadFiles />
          </DialogContentText>
        </DialogContent>
      </Dialog>
      { data !== null ? <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={open}
              onClose={handleClose}
              autoHideDuration={3000}
              message={data}
            ></Snackbar> : <p></p> }
      
    </div>
  );
}