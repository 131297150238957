import React from 'react';
import * as LottiePlayer from "@lottiefiles/lottie-player";
import { Typography } from '@mui/material';

export default function Loading() {
  return (
    <div style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: 20,
        width: '80%'
      }}
      align="center"
      >
        <Typography variant="body2">
            espere un instante...
        </Typography>
        <lottie-player
          autoplay
          loop
          mode="normal"
          src="https://assets1.lottiefiles.com/packages/lf20_a2chheio.json"
          style={{ width: "100px", height: "100px" }}
          ></lottie-player>
        
    </div>
  )
}
