import React, { Fragment , useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Divider, Grid, Box,  Typography } from '@mui/material';
import { Link as MuiLink } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import CardOrganization from '../../components/Organizations/CardOrganization';
import AddOrganization from '../../components/Organizations/AddOrganization';
import ConfirmSuscription from '../../components/Account/Suscription/ConfirmSuscription';

// Check to see if this is a redirect back from Checkout
const query = new URLSearchParams(window.location.search);

export default function Organizations(props) {
  const data = props.data;
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');

  React.useEffect(() => {
    if (query.has('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }
    else {
      setSuccess(false);
    }
  }, [sessionId]);

  return (
    <div style={{ marginLeft: 8, marginRight: 8}}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h2>Mis organizaciones</h2>
            <Typography variant="body1" color="text.secondary" style={{marginTop: -16}}>
              Las organizaciones en este listado, podrán emitir facturas con validéz fiscal siempre y cuando hayan sido perfiladas correctamente. 
              <Divider />
              {success && query.has('session_id') && sessionId !== "" ? <ConfirmSuscription session_id = { sessionId } /> : "" }
            </Typography>
          </Grid>
          { data.count > 0 ?
          <Grid item xs={12}>
            <AddOrganization />
          </Grid>
          : null }
        </Grid>
        <br />
        { data.count > 0 ? 
        <Box sx={{ flexGrow: 1 }}>
          <Grid item container spacing={1}>
            { data.organizations.map(item => 
              <Grid item xs={3}>
                <MuiLink to={`/organizations/${item._id}/resume`} component={RouterLink} underline="none" >
                  <CardOrganization item={item} />
                </MuiLink>
              </Grid>
            )}
          </Grid>
        </Box>
        : 
        <Box sx={{ flexGrow: 1 }}>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
                <div align="center">
                <lottie-player
                  autoplay
                  loop
                  mode="normal"
                  src="https://assets1.lottiefiles.com/packages/lf20_c4udnllp.json"
                  style={{ width: "300px", height: "300px" }}
                  ></lottie-player>
                  <Typography variant="h6">
                    Crear organización
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    Para comenzar, usa esta opción <AddOrganization />
                  </Typography>
                </div>
            </Grid>
          </Grid>
        </Box>
        }
    </div>
  )
}

const Item = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  elevation: 0,
  color: theme.palette.text.secondary,
}));