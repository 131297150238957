import React , { useState , useEffect, useRef }from 'react';
import axios from '../Axios/Axios';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CardIntCenter from './CardIntegrationCenter';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { Divider, Stepper } from '@mui/material';
import CheckList from './CheckList';
import Profile from '../Auth/Profile';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

function List() {
  const [isLoading, setIsLoading] = useState(true);
  const shouldLog = useRef(true);

  const [data , setData] = useState([]);

  const getData = async () => {
    const response = await axios.get("/organizations")
    setData(response.data.organizations);
    setIsLoading(false);
  }

  useEffect( ()=> {
    if(shouldLog.current){
      shouldLog.current = false;
      getData();
    }
  },[]);
  
  return (
    <div style={{marginTop: 0}}>
        <h2>Centro de integración</h2>
        <Typography variant="body1" color="text.secondary" style={{marginTop:0}}>
          Estimado usuario, bienvenido al centro de integración. Estas son las primeras acciones a considerar para iniciar con la facturación electrónica de su organización. 
          <Divider />
        </Typography>
        <Profile />
        <br />
        { data &&
          <div>
              { data.map(item => 
                <div key={item._id}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <CardIntCenter item={item} />
                      </Grid>
                    </Grid>
                  </Box>
                {/*
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={8} alignItems={'flex-end'}>
                        <Paper sx={{p:1}}>
                            <Typography gutterBottom variant="h6" component="div" style={{marginLeft:20}}>
                              Completar perfil
                            </Typography>
                            <Typography variant="body2" color="text.secondary" style={{marginLeft:20}}>
                                Cuando estes listo para usar tu <b>Prod API Key</b>, toma en cuenta que es necesario completar el perfil de tu organziación con la siguiente información.  
                            </Typography>
                            <br />
                          <CheckList item={item} />
                        </Paper>
                      </Grid>
                      <Grid item xs={4} alignItems={'flex-end'}>
                        <Paper sx={{p:1}}>
                            <Typography gutterBottom variant="h6" component="div" style={{marginLeft:20}}>
                              ¿Listo para Facturar?
                            </Typography>
                            <Typography variant="body2" color="text.secondary" style={{marginLeft:20}}>
                                Al día de hoy, se tiene cuenta con un avance en la documentación del ...  
                            </Typography>
                            
                        </Paper>
                      </Grid>
                    </Grid>
                </Box>
              */}
                </div>
              )}
          </div>
        }
    </div>
  )
}

export default List