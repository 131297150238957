import React, { useState } from 'react';
import { Button, Divider, LinearProgress, Link as MuiLink, Typography } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useParams } from 'react-router-dom';
import axios from '../../components/Axios/Axios';
import ReadInvoice from '../../components/Invoices/ReadInvoice';


export default function InvoiceDetails() {
  // 1. Read parameters
  const { id , invoice_id } = useParams(); // Unpacking and retrieve id and client id
  // for read client
  const [invoice , setInvoice] = React.useState([]);
  const shouldLog = React.useRef(true);
  const [loading , setLoading] = useState(false);
  // 2. Prepare the request
  const getInvoice = async () => {
    setLoading(true);
    await axios.get(`/invoices/${invoice_id}` , { params: { org_id: id } } )
    .then((response) =>{
        setInvoice(response.data.invoice);
      setLoading(false);
    }).catch((error) =>{
      console.error(error.message);
    });
  }

  // 3. Run the request
  React.useEffect(() => {
    if(shouldLog.current){
      getInvoice();
      shouldLog.current = false;
    }
  }, []);

  return (
    <div style={{marginTop: -80}}>
      <MuiLink to={`/organizations/${id}/facturas`} component={RouterLink} underline="none" >
        <Button size='small' startIcon={<ArrowBack />}> Back to invoices</Button>
      </MuiLink> / Invoice details
      { loading ? <LinearProgress /> : <ReadInvoice invoice={invoice} />  }
    </div>
  )
}
