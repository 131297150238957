import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Routes, Route , useSearchParams} from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Navbar from './components/template/Navbar';
import Loading from './components/template/Loading';
import AccountNotVerified from './components/template/AccountNotVerified';
import { getApp } from './helpers/helpers';
import { PageRouter , AppRouter } from './routers/Routers';

const Theme = createTheme({
  palette: {
    mode: 'light',
    //background: {
      //default: "#000"
    //},
    //text: {
      //default: "#000"
    //}
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 12,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
    ].join(','),
  },
});

function App() {
  const CurrentApp = getApp();
  const { isLoading , isAuthenticated } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={Theme}>
        {isAuthenticated ? <Navbar /> : searchParams.get("error") !== null && searchParams.get("error_description") !== null ?  <AccountNotVerified error={ searchParams.get("error_description") } />  : null }
        <CurrentApp />
    </ThemeProvider>
  );
}

export default App;
