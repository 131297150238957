import React, { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import * as LottiePlayer from "@lottiefiles/lottie-player";
import { Button, Divider, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import TextAnimation from '../../helpers/TextAnimation';
import { Link as MuiLink } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";

export default function Home() {
  const { isAuthenticated , loginWithRedirect , user} = useAuth0();

  return (
    <div style={{
      position: 'absolute',
      left: '50%',
      top: '45%',
      transform: 'translate(-50%, -50%)',
      fontSize: 20,
      width: '50%',
    }}
    align="center"
    >
      <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography variant="h3">
                <img src="/images/logo.png" width={72} align="absmiddle"/>
            </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3">
            <TextAnimation />
            <Divider sx={{padding: 2}}/>
          </Typography>
        </Grid>
        { isAuthenticated ? 
        <Fragment>
            <Grid item xs={12}>
                <MuiLink to={"/organizations"} component={RouterLink} >
                    <Button color="primary" variant="contained">Continuar</Button>
                </MuiLink>
            </Grid>
        </Fragment>
        : 
        <Fragment>
            <Grid item xs={12}>
                <Button color="primary" variant="contained" onClick={() => loginWithRedirect() }>Iniciar sesión</Button>    
            </Grid>
        </Fragment>
        }
      </Grid>
    </Box>
    </div>
  )
}
