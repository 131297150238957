import * as React from 'react';
import { useState , useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import InvoiceIcon from '@mui/icons-material/Description';
import People from '@mui/icons-material/People';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ContactIcon from '@mui/icons-material/PermContactCalendar';
import Typography from '@mui/material/Typography';
import { Button, Link as MuiLink } from '@mui/material';
import { Link as RouterLink , useLocation } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useParams } from 'react-router-dom';

import HasManyOrgs from './HasManyOrgs';

import axios from '../Axios/Axios';
import Steps from './Steps';

const data = [
  { icon: <InvoiceIcon />, label: 'Facturas' },
  { icon: <People />, label: 'Clientes' },
  { icon: <ShoppingCartIcon />, label: 'Productos' },
];

const opcionesConfig = [
    { icon: <ContactIcon />, label: 'Legal' },
    { icon: <VerifiedUserIcon />, label: 'Certificados' },
  ];

const FireNav = styled(List)({
  '& .MuiListItemButton-root': {
    paddingLeft: 24,
    paddingRight: 24,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 16,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 14,
  },
});

export default function CustomizedList(props) {
  const [isLoading, setIsLoading] = useState(true);
  const shouldLog = useRef(true);
  const [dataOrg, setDataOrg] = useState([]);
  const [open, setOpen] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const { pathname } = useLocation();
  const { id } = useParams(); // Unpacking and retrieve id

  const getDataOrg = async () => {
    const response = await axios.get("/organizations/" + id);
    const steps = response.data.organization.pending_steps;
    const compleatados = steps.filter(step => step.is_ready === true ).length;
    setDataOrg(compleatados);
    setIsLoading(false);
  }

  useEffect( ()=> {
    if(shouldLog.current){
      shouldLog.current = false;
      getDataOrg();
    }
    
  },[]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleMessage = () => {
    setOpenDialog(true);
  };
    
  const handleClose = () => {
      setOpenDialog(false);
  };
  
  return (
    <Box sx={{ display: 'flex' }} >
      <ThemeProvider
        theme={createTheme({
          components: {
            MuiListItemButton: {
              defaultProps: {
                disableTouchRipple: true,
              },
            },
          },
          palette: {
            mode: 'dark',
            primary: { main: 'rgb(102, 157, 246)' },
            background: { paper: 'rgb(5, 30, 52)' },
          },
        })}
      >
        <Paper elevation={0} sx={{ width: "100%" }}>
          <Box sx={{ height: "100vh"  , background: "rgb(5, 30, 52)"}}>
          <FireNav component="nav" disablePadding>
            { dataOrg && dataOrg.length > 0 ? "" : ""/*"Aún no registra una organización"*/}
            <List component="div" role="group">
            <MuiLink to={"resume"} component={RouterLink} underline="none">
              <ListItemButton sx={{ height: 56 , marginTop:6, borderLeft: pathname.split('/').pop() === "resume" ? 3 : 0}}>
                  <ListItemText
                  primary={ props.data.legal.tax_id  }
                  secondary={ props.data.legal.name  }
                  primaryTypographyProps={{
                      color: 'primary',
                      fontWeight: 'medium',
                      variant: 'body2',
                      //color: pathname.split('/').pop() === "resume" ? 'primary' : '#fff'
                  }}
                  />
              </ListItemButton>
              </MuiLink>
              
            </List>
            <Divider />
            <Box
              sx={{
                bgcolor: open ? 'rgba(71, 98, 130, 0.2)' : null,
                pb: open ? 2 : 0,
              }}
            >
              <ListItemButton
                alignItems="flex-start"
                onClick={() => setOpen(!open)}
                sx={{
                  px: 3,
                  pt: 2.5,
                  pb: open ? 0 : 2.5,
                  '&:hover, &:focus': { '& svg': { opacity: open ? 1 : 0 } },
                }}
              >
                <ListItemText
                  primary="Datos de la organización"
                  primaryTypographyProps={{
                    fontSize: 15,
                    fontWeight: 'medium',
                    lineHeight: '20px',
                    mb: '2px',
                  }}
                  secondary="Certificados, Datos fiscales"
                  secondaryTypographyProps={{
                    noWrap: true,
                    fontSize: 12,
                    lineHeight: '16px',
                    color: open ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0.5)',
                  }}
                  sx={{ my: 0 }}
                />
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    opacity: 0,
                    transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                    transition: '0.2s',
                  }}
                />
              </ListItemButton>
              {open &&
                opcionesConfig.map((item) => (
                  <MuiLink to={item.label.toLowerCase() } component={RouterLink} underline="none">
                    <ListItemButton key={item.label} sx={{ 
                      borderLeft: pathname.split('/').pop() === item.label.toLowerCase() ? 3 : 0 , 
                      py: 0, 
                      minHeight: 32, 
                      background: pathname.split('/').pop() === item.label.toLowerCase() ? 'rgba(255,255,255,.1)' : 'primary' }}>
                      <ListItemIcon sx={{ color: pathname.split('/').pop() === item.label.toLowerCase() ? 'inherit' : 'primary'}}>
                        {item.icon} 
                      </ListItemIcon>
                      <ListItemText
                        primary={item.label} 
                        primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' ,
                        color: pathname.split('/').pop() === item.label.toLowerCase() ? 'primary' : '#fff' 
                        }}
                      />
                    </ListItemButton>
                  </MuiLink>
                ))}
            </Box>
            
            <Box
              sx={{
                bgcolor: open ? 'rgba(71, 98, 130, 0.2)' : null,
                pb: open ? 2 : 0,
              }}
            >
              <ListItemButton
                alignItems="flex-start"
                onClick={() => setOpen(!open)}
                sx={{
                  px: 3,
                  pt: 2.5,
                  pb: open ? 0 : 2.5,
                  '&:hover, &:focus': { '& svg': { opacity: open ? 1 : 0 } },
                }}
              >
                <ListItemText
                  primary="Menú de opciones"
                  primaryTypographyProps={{
                    fontSize: 15,
                    fontWeight: 'medium',
                    lineHeight: '20px',
                    mb: '2px',
                  }}
                  secondary="Facturas, Clientes, Productos"
                  secondaryTypographyProps={{
                    noWrap: true,
                    fontSize: 12,
                    lineHeight: '16px',
                    color: open ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0.5)',
                  }}
                  sx={{ my: 0 }}
                />
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    opacity: 0,
                    transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                    transition: '0.2s',
                  }}
                />
              </ListItemButton>
              {open &&
                data.map((item) => (
                  <MuiLink to={ dataOrg >= 2 ? item.label.toLowerCase() : false } component={RouterLink} underline="none" onClick={dataOrg >= 2 ? "" : handleMessage}>
                    <ListItemButton key={item.label} sx={{ 
                      py: 0, minHeight: 32, 
                      borderLeft: pathname.split('/').pop() === item.label.toLowerCase() ? 3 : 0 ,
                      background: pathname.split('/').pop() === item.label.toLowerCase() ? 'rgba(255,255,255,.1)' : 'primary'
                      }}
                      disabled={dataOrg >= 2 ? false : true }
                      >
                      <ListItemIcon sx={{color: pathname.split('/').pop() === item.label.toLowerCase() ? 'inherit' : 'primary'}}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.label}
                        primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' ,
                        color: pathname.split('/').pop() === item.label.toLowerCase() ? 'primary' : '#fff' 
                        }}
                      />
                    </ListItemButton>
                    </MuiLink>
                   
                ))}
            </Box>
            <Divider />
          </FireNav>
          </Box>
        </Paper>
      </ThemeProvider>
      <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogContent>
            <DialogContentText>
                <h3 style={{marginLeft: 4, color: "#000"}}>Acción requerida</h3>
                <Typography variant="body1" color="text.secondary" style={{marginTop:-10, marginLeft: 4}}>
                    Es necesario completar los datos de la organización, así como la carga del certificado de sello digital.
                </Typography>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button autoFocus onClick={handleClose} variant="contained" color="primary">
                Continuar
            </Button>
            </DialogActions>
        </Dialog>
    </Box>
  );
}