import React from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import AddIcon from '@mui/icons-material/Add';
import * as LottiePlayer from "@lottiefiles/lottie-player";
import { Button, IconButton, Link as MuiLink , Typography} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link as RouterLink } from "react-router-dom";
import { useParams } from 'react-router-dom';
import LectorQR from '../QRReader/QRReader';


const columns = [
  { name: 'uuid', header: 'Folio fiscal',defaultFlex: 3 },
  { name: 'customer', header: 'RFC del cliente',defaultFlex: 2 ,
    render: ({ data }) => {
      return <span>{data.customer.tax_id}</span>;
    }
  },
  { name: 'legal', header: 'Nombre del cliente',defaultFlex: 2 ,
    render: ({ data }) => {
      return <span>{data.customer.legal_name}</span>;
    }
  },
  { name: 'type', header: 'Tipo de comprobante',defaultFlex: 2 },
  { name: 'total' , header: 'Monto total',  type: 'number' , textAlign: 'end', defaultFlex: 1 },
  { name: 'detail' , header: 'Acciones' , textAlign: 'center', defaultFlex: 1 , 
    render: ({ data }) => <MuiLink to={`/organizations/${data.org_id}/facturas/${data._id}`} component={RouterLink} >
      <IconButton size='small'>
        <VisibilityIcon />
        {/*<LectorQR qrCode={data.uuid} />*/}
      </IconButton>
    </MuiLink>
  }
];

const filterValue = [
  { name: 'uuid', operator: 'contains', type: 'string', value: '' },
  { name: 'customer', operator: 'contains', type: 'string', value: '' },
  { name: 'legal', operator: 'contains', type: 'string', value: '' },
  { name: 'type', operator: 'contains', type: 'string', value: '' },
  { name: 'total'      , operator: 'gte', type: 'number', value: 0, },
];

const gridStyle = { minHeight: 525 , fontSize: 11 };

export default function InvoiceList(props) {
    const { id } = useParams(); // Unpacking and retrieve id org
    const data = props.invoices;
  return (
    <div>
      <br />
        { data.count > 0 ?
        <React.Fragment>
            <MuiLink to={`/organizations/${id}/facturas/create`} component={RouterLink} underline="none" >
              <Button color="primary" startIcon={<AddIcon />} variant="contained">Agregar</Button>
            </MuiLink>
            <br /><br />
            <ReactDataGrid
                idProperty="id"
                showHoverRows={true}
                columns={columns}
                dataSource={data.invoices}
                style={gridStyle}
                defaultFilterValue={filterValue}
                defaultLimit={10}
                pagination={true}
            />
        </React.Fragment>
        : 
        <div align="center">
        <lottie-player
          autoplay
          loop
          mode="normal"
          src="https://assets1.lottiefiles.com/packages/lf20_c4udnllp.json"
          style={{ width: "300px", height: "300px" }}
          ></lottie-player>
          <Typography variant="body1" color="text.secondary">
            Aún no tienes facturas, puedes comenzar creando una nueva.
          </Typography>
          <br />
          <Typography variant="h6">
          <MuiLink to={`/organizations/${id}/facturas/create`} component={RouterLink} underline="none" >
            <Button color="primary" startIcon={<AddIcon />} variant="contained">Agregar</Button>
          </MuiLink>
          </Typography>
       </div>
        }
    </div>
    
  )
}
