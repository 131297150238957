import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { 
  ListItem , 
  ListItemButton , 
  ListItemAvatar, 
  ListItemText , List , Button, Avatar, Typography ,
  Grid , Paper
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import { useParams, useNavigate } from 'react-router-dom';


export default function PendingSteps(props) {
  const [steps,setSteps] = React.useState(props.steps);
  //const avance = (Math.round( steps.filter(step => step.is_ready === true ).length) / steps.length ) * 100;
  const compleatados = steps.filter(step => step.is_ready === true ).length;
  const { id } = useParams(); // Unpacking and retrieve id
  const navigate = useNavigate();

  const navTo = (page) => {
    navigate(`/organizations/${id}/legal`);
  }

  return (
    <>  
    <h3>Perfil de la organización</h3>
    <Typography variant="body1" color="text.secondary" style={{marginTop: -16}}>
      Esta organización ha completado { <b>{compleatados} de {steps.length} pasos necesarios </b> } para emitir facturas que tengan validéz fiscal. {compleatados < 3 ? "Por favor, complete los pasos faltantes." : ""}
    </Typography>
    <Grid container spacing={0}>
      <Grid item xs={8}>
        <Item elevation={0} >
          <Box>
            <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
              <ListItem
                    key={"x"}
                    secondaryAction={<Typography variant="body1" color="text.secondary">{"Estatus de actividad"}</Typography>}
                    disablePadding
                  >
                <ListItemButton>
                  <ListItemText id={"i"} primary={<Typography variant="body1" color="text.secondary">{"Nombre de la actividad"}</Typography>} /*secondary={"pasos necesarios.."}*/ />
                  </ListItemButton>
              </ListItem>
              {steps.map( step => {
                const labelId = step.type;
                return (
                  <ListItem
                    key={step.description}
                    secondaryAction={
                      <Button 
                        variant="contained" 
                        size="small" 
                        color={step.is_ready ? "primary" : "error" }
                        sx={{ width: 140 }}
                        onClick={navTo}
                        >
                        {step.is_ready ? "Completado" : "Pendiente" }
                      </Button>
                      //moment(client.createdAt).format('DD/MM/YYYY')
                    }
                    disablePadding
                    onClick={navTo}
                  >
                    <ListItemButton>
                      <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'default' }} >{step.is_ready ? <DoneIcon /> : <ErrorIcon />}</Avatar>
                      </ListItemAvatar>
                      <ListItemText id={labelId} primary={step.type} secondary={step.description} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Item>
      </Grid>
    </Grid>
    </>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
