import { AppRouter , PageRouter } from "../routers/Routers";

export const APPS = [
    {
        subdomain : "app" ,
        app : AppRouter ,
        main: false
    },
    {
        subdomain : "" ,
        app : PageRouter ,
        main: true
    },
]