import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { IconButton,CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../../components/Axios/Axios';

export default function DeleteCertificate(props) {
  const { id } = useParams(); // Unpacking and retrieve id
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [data , setData] = React.useState(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async (event) => {
    setIsLoading(true);
    const response = await axios.delete(`/certificates/${props.noCertificate}`);
    setData(response.data.data);
    navigate(`/organizations/${id}/resume`, { replace: true });
    /*
    setTimeout(() => {
        window.location.href = "/dashboard/certificados"
      }, "2000")
    */
    
  };

  return (
    <div>
      <IconButton component="label" onClick={handleClickOpen}><RemoveCircleIcon /></IconButton>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" style={{background: 'rgba(0, 0, 0, 0.1)'}}>
            <Typography variant="body1" color="text.primary">
                <b>Desactivar Certificado de Sello Digital</b>
            </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>
                Por favor, confirme si está de acuerdo en <b>desactivar</b> el certificado de sello digital con número <b>{props.noCertificate}</b>.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} variant="contained" color="inherit">
            Cancelar
          </Button>
          <Button 
            onClick={handleConfirm} 
            autoFocus variant="contained" 
            color="primary" 
            //size='small'
            startIcon={ isLoading ? <CircularProgress size={20} color={"inherit"} /> : <TaskAltIcon />}
            >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      { data !== null ? <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={open}
              onClose={handleClose}
              autoHideDuration={3000}
              message={data}
            ></Snackbar> : <p></p> }
      
    </div>
  );
}