import React from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import HelpIcon from '@mui/icons-material/Help';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useAuth0 } from "@auth0/auth0-react";
import Login from '../Auth/Login';
import Suscription from '../Account/Suscription/Suscription';

export default function MenuSettings() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const settings = ['Config. de Cuenta', 'Cerrar sesión'];
  const { logout , isAuthenticated , user } = useAuth0();
  

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const openConfigDialog = () => {
    alert("config page..");
  }

  const menuId = 'primary-search-account-menu';

  return (
    <>
        {/* Inicia Mesnu settings */}
        <Box sx={{ display: { xs: 'none', md: 'flex' } }} >
            {/*
            <Tooltip title="Notifications">
                <IconButton
                size="small"
                aria-label="show 17 new notifications"
                color="inherit"
                >
                <Badge badgeContent={4} color="primary">
                    <NotificationsIcon />
                </Badge>
                </IconButton>
            </Tooltip> 
            

            <Tooltip title="Support">
                <IconButton
                size="small"
                color="inherit"
                >
                <Badge>
                    <HelpIcon />
                </Badge>
                </IconButton>
                
            </Tooltip> 
            */}
          
        </Box>
        {
          isAuthenticated ?
          //muestra menú
          <>
            <Suscription />
            <Typography variant="subtitle1" gutterBottom>
              {user.email}
            </Typography>

            <Box sx={{ flexGrow: 0 }} px={1}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar sx={{ width: 32, height: 32, bgcolor: "#FFF" }} src={user.picture} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '33px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Typography textAlign="center" onClick={ 
                        setting === "Cerrar sesión" ? () => logout({ returnTo: window.location.origin }) : 
                        setting === "Config. de Cuenta" ? openConfigDialog : null }>{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
            </Box>
          </>
          :
          //esonde menú
          <Login />
        }
          {/* Termina Mesnu settings */}
    </>
  )
}
