import { Autocomplete, Button, CircularProgress, IconButton, InputAdornment, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import React, { Fragment } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { render } from "react-dom";
import axios from '../Axios/Axios';
var numeral = require('numeral');

export default class Items extends React.Component {

  state = {
    rows: [{}],
    openAutocomplete: false,
    options: [{}],
    loading: this.openAutocomplete && this.options.length === 0,
    price: [{}],
    total: 0,
  };
  
  handleChange = idx => e => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];
    const filtered = this.state.options.filter(function(el) { return el.product_key === value });
    if( name === "product_key" ){
        this.state.price[idx] = filtered[0].price;
        rows[idx] = {
            ['product_key']: filtered[0].product_key,
            ['description']: filtered[0].description,
            ['unit']: filtered[0].unit_key,
            ['quantity']: 1,
            ['price']: this.state.price[idx],
            ['subtotal'] : this.state.price[idx]
          };
    } else {
        rows[idx] = {
            ['product_key']: this.state.rows[idx].product_key,
            ['description']: this.state.rows[idx].description,
            ['unit']: this.state.rows[idx].unit,
            [name]: value,
            ['price']: this.state.price[idx],
            ['subtotal']: name === "quantity" ? ( value * this.state.price[idx]) : 0
        }
    }
    
    let temp = 0;

    rows.map((item) => {
        temp += item.subtotal
    });

    this.setState({
      rows,
      total: temp
    });

  };
  handleAddRow = () => {
    const item = {
      //product_key: "",
      //description: "",
      //quantity: 0,
      //price: 0,
      //subtotal: 0
    };
    this.setState({
      rows: [...this.state.rows, item]
    });
  };
  handleRemoveRow = () => {
    this.setState({
      rows: this.state.rows.slice(0, -1)
    });
  };
  handleRemoveSpecificRow = (idx) => () => {
    const rows = [...this.state.rows]
    rows.splice(idx, 1)
    let temp = 0;
    if ( rows.length > 0 ) {
        rows.map((item) => {
            temp += item.subtotal
        });
    }
    this.setState({ rows , total: temp});
  }
  // to get client list
  componentDidMount() {
    
     // do something
     let active = true;
     
     (async () => {
      const url = window.location.href;
      const array = url.split('/');
      const lastsegment = array[array.length-3];
       const params = { org_id : lastsegment }
       if (active) {
         await axios.get('/products' , { params: params })
         .then((results) =>{
            this.setState({options: results.data.products});
         })
         .catch((error) =>{
           console.error(error);
         })
       }
     })();
  }

  componentDidUpdate() {
    if (this.props.onChange) {
      this.props.onChange(this.state);
    }
  }


  render() {
    return (
      <div>
        <div className="container" style={{fontSize: 11}}>
            <table
            className="table table-bordered table-hover"
            id="tab_logic"
            >
            <thead>
                <tr>
                {/*<th className="text-center"> # </th>*/}
                <th className="text-center"> Producto / Servicio </th>
                <th className="text-center"> Descripción </th>
                <th className="text-center"> Unidad </th>
                <th className="text-center"> Cantidad </th>
                <th className="text-center"> Precio</th>
                <th className="text-center"> Subtotal </th>
                <th />
                </tr>
            </thead>
            <tbody>
                {this.state.rows.map((item, idx) => (
                <tr id="addr0" key={idx}>
                    {/*<td>{idx}</td>*/}
                    <td width="35%">
                    {/*
                    <Autocomplete
                        filterSelectedOptions
                        sx={{ width: '100%' }}
                        open={this.state.openAutocomplete}
                        onOpen={() => {
                            this.setState({ openAutocomplete: true });
                        }}
                        onClose={() => {
                            this.setState({ openAutocomplete: false });
                        }}
                        isOptionEqualToValue={(option, value) => option.description === value.description }
                        getOptionLabel={(option) => option.product_key }
                        getOptionsDisabled
                        options={this.state.options}
                        loading={this.state.loading}
                        size="small"
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            name="product_key"
                            label="Elegir producto o servicio"
                            variant="filled"
                            value={this.state.rows[idx].product_key}
                            onChange={this.handleChange(idx)}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                <React.Fragment>
                                    {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                                ),
                            }}
                            />
                        )}
                    />*/}
                    
                    <TextField
                        type="text"
                        select
                        name="product_key"
                        variant="filled"
                        sx={{width: "100%"}}
                        size="small"
                        value={this.state.rows[idx].product_key}
                        onChange={this.handleChange(idx)}
                        className="form-control"
                        InputProps={{
                            //endAdornment: <this.ChooseProduct />
                        }}
                    >
                        {this.state.options.map((option) => (
                        <MenuItem 
                        sx={{fontSize: 11}}
                        key={option.id} 
                        value={ option.product_key } 
                        selected={ this.state.rows[idx].product_key === option.product_key ? true : false } 
                        >
                          <Typography variant="body2">
                          { option.product_key }
                          </Typography>
                        </MenuItem>
                    ))}
                    </TextField>
                    
                    </td>
                    <td width="32%">
                    <TextField
                        type="text"
                        name="description"
                        size="small"
                        variant="filled"
                        sx={{width: "100%"}}
                        value={this.state.rows[idx].description}
                        onChange={this.handleChange(idx)}
                        className="form-control"
                        InputProps={{
                            readOnly: true,
                            style: {fontSize: 13}
                        }}
                    />
                    </td>
                    <td width="7%">
                    <TextField
                        name="unit"
                        size="small"
                        variant="filled"
                        value={this.state.rows[idx].unit}
                        //defaultValue={1}
                        onChange={this.handleChange(idx)}
                        className="form-control"
                        InputProps={{
                          readOnly: true,
                      }}
                    />
                    </td>
                    <td width="7%">
                    <TextField
                        name="quantity"
                        size="small"
                        variant="filled"
                        value={this.state.rows[idx].quantity}
                        //defaultValue={1}
                        onChange={this.handleChange(idx)}
                        className="form-control"
                        inputProps={{
                            step: 1,
                            min: 1,
                            max: 9999,
                            type: 'number',
                          }}
                    />
                    </td>
                    <td width="8%">
                    <TextField
                        type="text"
                        name="price"
                        size="small"
                        variant="filled"
                        value={this.state.rows[idx].price}
                        onChange={this.handleChange(idx)}
                        className="form-control"
                        InputProps={{
                            readOnly: true,
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                    </td>
                    <td width="8%">
                    <TextField
                        type="text"
                        name="subtotal"
                        size="small"
                        variant="filled"
                        value={this.state.rows[idx].subtotal}
                        onChange={this.handleChange(idx)}
                        className="form-control"
                        InputProps={{
                            readOnly: true,
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                    </td>
                    <td>
                    <Tooltip title="Eliminar producto / servicio">
                    <IconButton color="error" variant="contained"
                        className="btn btn-outline-danger btn-sm"
                        onClick={this.handleRemoveSpecificRow(idx)}
                    >
                        <DeleteIcon />
                    </IconButton>
                    </Tooltip>
                    </td>
                </tr>
                ))}
            </tbody>
            </table>
            <Button onClick={this.handleAddRow} className="btn btn-primary">
             + Agregar producto
            </Button>
            {/*
            <Button
            onClick={this.handleRemoveRow}
            className="btn btn-danger float-right"
            >
            Eliminar último renglón
            </Button>*/}
        </div>
        <div align="right" style={{marginRight: 40}}>
            <Typography variant="h6" color="text.secondary">
                Importe antes de impuestos: ${numeral(this.state.total).format('0,0')} 
            </Typography>
        </div>
      </div>
    );
  }
}