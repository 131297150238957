import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions , Avatar , Grid } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';


export default function CardOrganization(props) {
  const [item , setItem] = React.useState(props.item);
  return (
    <React.Fragment>
    { item !== "" ? 
    <Card sx={{height:175}}>
      <CardActionArea>
        <CardHeader sx={{height:175}}
            avatar={
              <Avatar 
                sx={{ bgcolor: typeof item.logo_url !== 'undefined' ? "#fefefe" : "#051e34" , width: 48, height: 48 }} 
                aria-label="recipe" 
                src={ typeof item.logo_url !== 'undefined' ? item.logo_url : ""}
                variant="square" >
                {item.legal.name.charAt(0).toUpperCase()}
              </Avatar>
            }
            title={<Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{item.legal.name}</Typography>}
            subheader={item.legal.tax_id}
          />
        <CardContent>
        </CardContent>
      </CardActionArea>
      {/*
      <CardActions>
        <Button size="small" color="primary">
          Elegir
        </Button>
      </CardActions>
      */}
    </Card>
    : 
    <Stack spacing={1}>
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rectangular" width={210} height={60} />
    </Stack>
    } 
    </React.Fragment>
  );
}