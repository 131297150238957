import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import { Button, TextField, Grid, Typography, Alert, Snackbar, CardHeader,  ListItemButton, ListItemAvatar, ListItem, Dialog, DialogContent, DialogContentText, DialogActions, Autocomplete, CircularProgress, touchRippleClasses, Select, IconButton, Paper } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import SwitchProductIcon from '@mui/icons-material/FindReplace';
import { useForm  } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import * as yup from "yup";
import { useParams, useNavigate } from 'react-router-dom';
import DeleteProduct from "./DeleteProduct";
import axios from '../Axios/Axios';
import SearchProduct from "./SearchProduct";

const schema = yup.object({
    product_key: yup.string().required(),
    description: yup.string().required(),
    taxes: yup.string().required(),
    unit_key: yup.string(),
});

export default function ProductForm(props) {
  //for autocomplete to product and services
  const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = openAutocomplete && options.length === 0;

  //for cat unit
  const [openAutoUnit, setOpenAutoUnit] = React.useState(false);
  const [optionsUnit, setOptionsUnit] = React.useState([]);
  const loadingUnit = openAutoUnit && optionsUnit.length === 0;

  const [open, setOpen] = React.useState(false);
  const [openCatProdServ, setOpenCatProdServ] = React.useState(false);
  const [openCatUnit, setOpenCatUnit] = React.useState(false);
  const { id } = useParams(); // Unpacking and retrieve id
  const navigate = useNavigate();

  const  product = props.product;
  const [productKey, setProductKey] = React.useState(product.product_key);
  const [unitKey, setUnitKey] = React.useState(product.unit_key + " - " + product.unit_name);
  // tax
  const [tax] = React.useState("002");
  
  const { register, handleSubmit, getValues, setValue, formState: { errors, isDirty } } = useForm({
    resolver: yupResolver(schema)
  });

  // form state
  //const isFormEdited = formState.isDirty;

  const onSubmit = async (data) => {

    const newData = {
        product_id:  product._id,
        product_key: data.product_key_new ? data.product_key_new : data.product_key,
        description: data.description,
        price:  Number(data.price),
        //taxes:  data.taxes,
        sku:    data.sku,
        unit_key:  data.unit_key_new ? data.unit_key_new.split(" - ")[0] : product.unit_key,
        unit_name: data.unit_key_new ? data.unit_key_new.split(" - ")[1] : product.unit_name,
    }

    await axios.put('/products' , newData)
    .then((repon) => {
        setOpen(true);
        setTimeout(() => {
            handleClose();
            navigate(`/organizations/${id}/productos`, { replace: true });
        }, 3000);
    })
    .catch((error) =>{
        alert(error);
    })
  };

  const handleClose = () => {
    setOpen(false);
  };

  // to get catalog products and services
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }
    
    (async () => {
      //await sleep(1e3); // For demo purposes.
      const params = { org_id : id }
      if (active) {
        await axios.get('/products/catalog' , { params: params })
        .then((results) =>{
          setOptions(results.data.products);
        })
        .catch((error) =>{
          console.error(error);
        })
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!openAutocomplete) {
        setOptions([]);
    } 
  }, [openAutocomplete]);

  // to get unit catalog
  React.useEffect(() => {
    let active = true;

    if (!loadingUnit) {
      return undefined;
    }
    
    (async () => {
      //const params = { org_id : id }
      if (active) {
        await axios.get('/products/catclaveunidad' /*, { params: params }*/)
        .then((results) =>{
          setOptionsUnit(results.data);
        })
        .catch((error) =>{
          console.error(error);
        })
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingUnit]);

  React.useEffect(() => {
    if (!openAutoUnit) {
        setOptionsUnit([]);
    } 
  }, [openAutoUnit]);

  const SwitchProduct = () => (
    <Tooltip title="Buscar y reemplazar clave de producto o servicio SAT">
        <IconButton onClick={handleOpenDialogCatServices}>
        <SwitchProductIcon />
        </IconButton>
    </Tooltip>
  );

  const handleOpenDialogCatServices = () => {
    setOpenCatProdServ(true);
  }

  const handleCloseDialogCatServices = () => {
    setOpenCatProdServ(false);
  };

  const handleAssingNewKey = () => {
    if(getValues("product_key_new"))
        setProductKey(getValues("product_key_new"));
    
    handleCloseDialogCatServices();
  }

  // for unit catalog
  const SwitchUnit = () => (
    <Tooltip title="Buscar y reemplazar unidad de medida">
        <IconButton onClick={handleOpenDialogUnit}>
            <SwitchProductIcon />
        </IconButton>
    </Tooltip>
  );

  const handleOpenDialogUnit = () => {
    setOpenCatUnit(true);
  }

  const handleCloseDialogUnit = () => {
    setOpenCatUnit(false);
  };

  const handleAssingNewUnit = () => {
    if(getValues("unit_key_new"))
        setUnitKey(getValues("unit_key_new"));
    
    handleCloseDialogUnit();
  }

  return (
    <div style={{marginTop: -50}}>
    <form onSubmit={handleSubmit(onSubmit)}>
        <Fragment>
        {errors.product_key ? 
            <Alert severity="info">
                Por favor, asegúrese que la clave de producto o servicio es la correcta.
            </Alert>: 
        ""}
        <Grid container spacing={0}>
            <Grid xs={6} > 
                <h3>Datos del producto o servicio</h3> 
                <Grid xs={12}>
                    <TextField
                        id="product_key"
                        label="Clave de producto o servicio SAT *"
                        size="small"
                        sx={{width: '96%' }}
                        InputProps={{
                            readOnly: true,
                            endAdornment: <SwitchProduct />
                        }}
                        error={errors.product_key}
                        value={productKey}
                        defaultValue={productKey}
                        variant="filled"
                        {...register("product_key")}
                    />
                </Grid>
                <br />
                <Grid xs={12}>
                <TextField
                    id="filled-select-currency-native"
                    label="Descripción *"
                    multiline
                    rows={2}
                    size="small"
                    sx={{width: '96%' }}
                    error={errors.description}
                    defaultValue={product.description}
                    variant="filled"
                    {...register("description")}
                    >
                    
                </TextField>

                </Grid> 
                
                <h3>Unidad de medida</h3> 
                <Grid xs={12}> 
                    <TextField
                    id="unit-key-id"
                    label="Clave de unidad"
                    InputProps={{
                        readOnly: true,
                        endAdornment: <SwitchUnit />
                    }}
                    size="small"
                    sx={{width: '96%' }}
                    error={errors.unit_key}
                    value={unitKey}
                    defaultValue={unitKey}
                    variant="filled"
                    {...register("unit_key")}
                    >
                    </TextField>
                </Grid>
            </Grid>
            
            <Grid xs={6}> 
                <h3>Información del precio</h3>
                <Grid xs={12}> 
                    <TextField
                        id="standard-helperText"
                        label="Precio unitario *"
                        defaultValue={ product.price }
                        variant="filled"
                        sx={{width: '33%' }}
                        placeholder={"$999"}
                        error={errors.price}
                        {...register("price")}
                    />
                    &nbsp;
                    <TextField
                    id="select-taxes"
                    select
                    label="Impuesto *"
                    sx={{width: '33%' }}
                    error={errors.taxes}
                    //onChange={handleChange}
                    defaultValue={ typeof product.taxes?.type === "undefined" ? tax : product.taxes?.type }
                    //SelectProps={{
                    //    native: true,
                    //}}
                    variant="filled"
                    {...register("taxes")}
                    >
                    {catTaxes.map((option) => (
                        <MenuItem 
                        key={option.id} 
                        value={ option.id } 
                        selected={ tax === option.value ? true : false } 
                        >
                        { option.description }
                        </MenuItem>
                    ))}
                </TextField>&nbsp;
                    <TextField
                    id="standard-helperText"
                    label="Precio total"
                    defaultValue={ ( product.price * 1.16).toFixed(2) }
                    variant="filled"
                    sx={{width: '32%' }}
                    placeholder={"19"}
                    error={errors.total_price} 
                    {...register("total_price")}
                    />
                </Grid>
                <br />
                <Grid xs={12}>
                <h3>Información adicional</h3>
                <TextField
                    id="standard-helperText"
                    label="Fecha de creación"
                    defaultValue={product.createdAt}
                    variant="filled"
                    sx={{width: '45%' }}
                    placeholder={"Mexico"}
                    error={errors.createdAt}
                    size="small"
                    {...register("createdAt")}
                />&nbsp;
                <TextField
                    id="standard-helperText"
                    label="SKU"
                    defaultValue={""}
                    variant="filled"
                    sx={{width: '54%' }}
                    placeholder={"TS00001"}
                    error={errors.sku}
                    size="small"
                    {...register("sku")}
                />
                </Grid>
            </Grid>
        </Grid>
        <br />
        <div align="left">
            <Grid xs={12}>
                <Button color="secondary" variant="outlined" onClick={()=> navigate(`/organizations/${id}/productos`, { replace: true }) }>Cancelar</Button>
                &nbsp; 
                <Button type="submit" color="primary" variant="contained" >Guardar</Button>
                &nbsp; 
                <Typography variant="caption" color="text.secondary" style={{marginLeft: 4}}>
                    last updated <b>{product.updatedAt}</b>
                </Typography>
            </Grid>
            <br />
            <Grid xs={12}>
                <h3>Danger zone</h3>
                    <ListItem
                        //key={product.legal_name}
                        sx={{background: "rgb(255, 238, 237)"}}
                        secondaryAction={ <DeleteProduct product_key={product.product_key} />}
                        disablePadding
                    >
                        <ListItemButton>
                            <ListItemAvatar>
                                <CardHeader sx={{height:75}}
                                title={<Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{"Eliminar producto"}</Typography>}
                                subheader={"Esta acción eliminará permanentemente al producto. Clic en el botón eliminar para continuar con esta acción."}
                                /> 
                            </ListItemAvatar>
                        </ListItemButton>
                    </ListItem>
            </Grid>
        </div>
        </Fragment>
    
    <Fragment>
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogContent>
            <DialogContentText>
                <Grid container spacing={1}>
                    <Grid item xs={12} sx={{/*background: "#FAFAFA"*/}}>
                    <div align="center">
                    <h3 style={{marginLeft: 4, color: "#000"}}>Producto actualizado</h3>
                    <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src="https://assets3.lottiefiles.com/packages/lf20_hFmz66beuw.json"
                            style={{ width: "200px", height: "200px" }}
                    />
                    <br />
                    <Typography variant="body1" color="text.secondary" style={{marginTop:0, marginLeft: 4}}>
                        El producte <b>{getValues("product_key")}</b> ha sido actualizado correctamente. 
                    </Typography>
                    <br />
                    </div>
                    </Grid>
                </Grid>
            </DialogContentText>
            </DialogContent>
        </Dialog>
    </Fragment>

    {/** Buscar clave de producto o servicio SAT */}
    <Fragment>
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={openCatProdServ}
            onClose={handleCloseDialogCatServices}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogContent>
            <DialogContentText>
                <Grid container spacing={1}>
                    <Grid item xs={5} sx={{/*background: "#FAFAFA"*/}}>
                    <div align="center">
                    <h3 style={{marginLeft: 4, color: "#000"}}>Reemplazar clave de producto o servicio</h3>
                    <Typography variant="body2" color="text.secondary" style={{marginTop:-14, marginLeft: 4}}>
                        Seleccione la nueva clave de producto o servicio. 
                    </Typography>
                    <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src="https://assets1.lottiefiles.com/packages/lf20_3cmzoitz.json"
                            style={{ width: "445px" }}
                    />
                    
                    </div>
                    </Grid>
                    <Grid item xs={7}>
                    <Autocomplete
                            id="asynchronous-demo"
                                sx={{ width: '95%' , marginLeft: 4 , marginTop: "2vh" }}
                                open={openAutocomplete}
                                onOpen={() => {
                                    setOpenAutocomplete(true);
                                }}
                                onClose={() => {
                                    setOpenAutocomplete(false);
                                }}
                                isOptionEqualToValue={(option, value) => option.description === value.description }
                                getOptionLabel={(option) => option.product_key + " - " +option.description }
                                options={options}
                                loading={loading}
                                //defaultValue={{ product_key: String(product.product_key).split(" - ")[0] , description: String(product.product_key).split(" - ")[1] }}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label="Ingrese clave o nombre de producto o servicio"
                                    variant="filled"
                                    helperText={getValues("product_key_new")}
                                    //defaultValue={{ product_key: String(product.product_key).split(" - ")[0] , description: String(product.product_key).split(" - ")[1] }}
                                    error={errors.product_key_new}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                        ),
                                    }}
                                    {...register("product_key_new")}
                                    />
                                )}
                        />
                    
                    </Grid>
                </Grid>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCloseDialogCatServices}>Cancelar</Button>
                <Button variant="contained" onClick={handleAssingNewKey}>Reemplazar</Button>
            </DialogActions>
        </Dialog>
    </Fragment>

    {/** Buscar clave de unidad SAT */}
    <Fragment>
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={openCatUnit}
            onClose={handleCloseDialogUnit}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogContent>
            <DialogContentText>
                <Grid container spacing={1}>
                    <Grid item xs={5} sx={{/*background: "#FAFAFA"*/}}>
                    <div align="center">
                    <h3 style={{marginLeft: 4, color: "#000"}}>Reemplazar clave de unidad de medida</h3>
                    <Typography variant="body2" color="text.secondary" style={{marginTop:-14, marginLeft: 4}}>
                        Seleccione la nueva clave de unidad de medida. 
                    </Typography>
                    <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src="https://assets1.lottiefiles.com/packages/lf20_3cmzoitz.json"
                            style={{ width: "445px" }}
                    />
                    
                    </div>
                    </Grid>
                    <Grid item xs={7}>
                    <Autocomplete
                        id="unit-name-id"
                            sx={{ width: '96%' , marginLeft: 0 }}
                            open={openAutoUnit}
                            onOpen={() => {
                                setOpenAutoUnit(true);
                            }}
                            onClose={() => {
                                setOpenAutoUnit(false);
                            }}
                            isOptionEqualToValue={(option, value) => option.Nombre === value.Nombre }
                            getOptionLabel={(option) => option.c_ClaveUnidad + " - " + option.Nombre}
                            options={optionsUnit}
                            loading={loadingUnit}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label="Ingrese clave o nombre de unidad de medida "
                                variant="filled"
                                helperText={getValues("unit_key_new")}
                                {...register("unit_key_new")}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {loadingUnit ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }}
                                />
                            )}
                        /> 
                    </Grid>
                </Grid>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCloseDialogUnit}>Cancelar</Button>
                <Button variant="contained" onClick={handleAssingNewUnit}>Reemplazar</Button>
            </DialogActions>
        </Dialog>
    </Fragment>
    </form>
    </div>
  );
}

//tax catalog
const catTaxes = [
    {
        id: '001',
        rate: 0.0,
        type: 'IVA',
        description: 'IVA (0.00%)',
        factor: "Tasa"
    },
    {
        id: '002',
        rate: 0.16,
        type: 'IVA',
        description: 'IVA (16.00%)',
        factor: "Tasa"
    },
    {
        id: '003',
        rate: 0.0,
        type: 'Exento',
        description: 'Exento (0.00%)',
        factor: "Exento"
    }
];
