import * as React from 'react';
import { Button, Typography } from '@mui/material';
import PDFIcon from '@mui/icons-material/PictureAsPdf';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import axios from '../../components/Axios/Axios';

import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export default function ToolBar(props) {

    const invoice = props.invoice;

    const [open, setOpen] = React.useState(false);
    const [openEmail, setOpenEmail] = React.useState(false);
    const [sended, setSended] = React.useState(false);
    const [data, setData] = React.useState(invoice.pdf);
    const [url , setURL]  = React.useState("");

    const download = () => {
        const linkSource = `data:application/pdf;base64,${invoice.pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = invoice.uuid;
    
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    
    const sendByEmail =  async () => {
        setOpenEmail(true);
        setSended(false);
        const data = {
            uuid: invoice.uuid,
            to: "dev.eraydel@gmail.com",
        }
        await axios.post(`/invoices/sendemail` , data )
        .then((response) =>{
            setSended(true);
            setTimeout( handleClose , 3000 );
            //setSended(false);
        }).catch((error) =>{
        console.error(error.message);
        });
        
    }

    const handleClickOpen = () => {
        setOpen(true);
        const blob = base64toBlob();
        setURL(URL.createObjectURL(blob));
    };
    
    const handleClose = () => {
        setOpen(false);
        setOpenEmail(false);
    };

    const base64toBlob = () => {
        
        const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
    
        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);
    
        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }
    
        return new Blob([out], { type: 'application/pdf' });
    };

    // Your render function
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div>
        <Stack direction="row" spacing={1}>
            <Button variant="outlined" startIcon={<PDFIcon />} onClick={handleClickOpen} /*onClick={download}*/ disabled={invoice.pdf !== "" ? false : true }>
              Preview
            </Button>
            <Button variant="contained" endIcon={<SendIcon />} onClick={sendByEmail} >
              Send
            </Button>
        </Stack>
        {/* Dialog for viwer pdf */}
        <Dialog
            open={open}
            fullWidth
            maxWidth={"lg"}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <div
                style={{
                    height: '100%',
                }}
            >
                    <Viewer fileUrl={url} plugins={[defaultLayoutPluginInstance]} />
                </div>
            </Worker>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={download} startIcon={<DownloadIcon />} autoFocus variant="outlined">Download PDF</Button>
            <Button onClick={handleClose} autoFocus variant="contained" startIcon={<CloseIcon />}>Cerrar</Button>
            </DialogActions>
        </Dialog>

        {/* Dialog for send by email */}
        <Dialog
            open={openEmail}
            fullWidth
            maxWidth={"sm"}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {sended && <>
                <h3 style={{marginLeft: 4, color: "#000"}}>Factura enviada</h3>
                El correo fue enviado correctamente.
            </>}
            {!sended && <>
                <h3 style={{marginLeft: 4, color: "#000"}}>Enviando factura</h3>
                Enviando factura electrónica por correo, espere unos instanes...
            </>}
            <div align="center">
            <lottie-player
                    autoplay
                    loop
                    mode="normal"
                    src="https://assets6.lottiefiles.com/packages/lf20_ebqz3ltq.json"
                    style={{ width: "400px" }}
            />
            </div>
            
            </DialogContentText>
            </DialogContent>
        </Dialog>
    </div>
  )
}
