import React, { Fragment } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, TextField, IconButton, Typography, Divider, Alert, Snackbar, InputAdornment } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import * as LottiePlayer from "@lottiefiles/lottie-player";
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import axios from '../Axios/Axios'
//https://www.sat.gob.mx/personas/resultado-busqueda?words=cat%C3%A1logos&locale=1462228413195&tipobusqueda=predictiva
//https://www.mongodb.com/docs/database-tools/installation/installation-macos/
//mongoimport --uri mongodb+srv://dev_app:EyNKg7g57OB3xGJ8@facturelo.lsweyzc.mongodb.net/facturelo-dev --collection cat_products_services_sat --type csv --file cat_products_services.csv --fields=product_key,description
// form
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from 'react-router-dom';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { alignProperty } from '@mui/material/styles/cssUtils';
import SearchProduct from './SearchProduct';

const schema = yup.object({
    product_key: yup.string().required(),
    description: yup.string().max(100).required(),
    price: yup.number().required(),
});


export default function AddProduct() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
    const [confirm , setConfirm] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = openAutocomplete && options.length === 0;
    const { id } = useParams(); // Unpacking and retrieve id
    const navigate = useNavigate();

    const { register, handleSubmit, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
      });
    
    const onSubmit = async (data) => {
        setIsLoading(true);
        //try to save the info
        data.org_id = id;
        await axios.post('/products' , data )
        .then((respon) => {
            setIsLoading(false);
            setConfirm(true);
            setTimeout(() => {
                handleClose();
                navigate(`/organizations/${id}/productos/${respon.data.id}`);
                //navigate(`/organizations/${id}/clientes/${respon.data.id}`);
            }, 3000);     
        })
        .catch((error) =>{
            alert(error);
        })
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    // to get catalog 
    React.useEffect(() => {
        let active = true;
    
        if (!loading) {
          return undefined;
        }
        
        (async () => {
          //await sleep(1e3); // For demo purposes.
          const params = { org_id : id }
          if (active) {
            await axios.get('/products/catalog' , { params: params })
            .then((results) =>{
              setOptions(results.data.products);
            })
            .catch((error) =>{
              console.error(error);
            })
          }
        })();
    
        return () => {
          active = false;
        };
    }, [loading]);
    
    React.useEffect(() => {
        if (!openAutocomplete) {
            setOptions([]);
        } 
    }, [openAutocomplete]);

  return (
    <Fragment>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClickOpen} variant="contained">Agregar</Button>
        <Dialog
            fullWidth={true}
            maxWidth={ confirm ? "sm" : "md" }
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogContent>
            <DialogContentText>
                <Grid container spacing={1}>
                { !confirm ?
                <>
                    <Grid item xs={6} sx={{background: "#FAFAFA"}}>
                    <h2 style={{marginLeft: 4, color: "#000"}}>Agregar producto</h2>
                    <Typography variant="body1" color="text.secondary" style={{marginTop:-20, marginLeft: 4}}>
                        Por favor, ayúdanos a crear un producto o servicio.
                    </Typography>
                    <div align="center">
                    <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src="https://assets9.lottiefiles.com/private_files/lf30_ft0cqvpu.json"
                            style={{ width: "300px", height: "300px" }}
                    />
                    <Typography variant="body2" color="text.secondary" style={{marginTop:-20}}>
                        <InfoIcon color='primary'/> La configuración de este producto/servicio te permitirá asociarlo fácilmente al momento de crear tus facturas.
                    </Typography>
                    </div>
                    </Grid>
                    
                    <Grid xs={6} sx={{marginTop: '10vh'}}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Autocomplete
                            id="asynchronous-demo"
                            sx={{ width: '96%' , marginLeft: 2 }}
                            open={openAutocomplete}
                            onOpen={() => {
                                setOpenAutocomplete(true);
                            }}
                            onClose={() => {
                                setOpenAutocomplete(false);
                            }}
                            isOptionEqualToValue={(option, value) => option.description === value.description}
                            getOptionLabel={(option) => option.product_key + " - " + option.description}
                            options={options}
                            loading={loading}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label="Clave producto o servicio SAT"
                                variant="standard"
                                error={errors.product_key}
                                helperText="Capture la clave del producto o servicio de acuerdo a la clasificación del SAT"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }}
                                {...register("product_key")}
                                />
                            )}
                        /> <br />
                        <TextField
                            id="standard-helperText"
                            label="Descripción *"
                            helperText="Capture la descripción del producto o servicio"
                            variant="standard"
                            sx={{ width: '96%' , marginLeft: 2 }}
                            placeholder={"Descripción del producto o servicio"}
                            error={errors.description}
                            size="small"
                            {...register("description")}
                        />
                        <br /><br />
                        <TextField
                            id="standard-helperText"
                            label="Precio *"
                            helperText="Capture el precio del producto o servicio"
                            variant="standard"
                            sx={{ width: '96%' , marginLeft: 2 }}
                            placeholder={"999"}
                            error={errors.price}
                            size="small"
                            {...register("price")}
                        />
                    </form>
                    </Grid>
                </>
                : 
                <Grid xs={12}>
                    <div align="center">
                    <h3 style={{marginLeft: 4, color: "#000"}}>Producto creado</h3>
                    <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src="https://assets3.lottiefiles.com/packages/lf20_hFmz66beuw.json"
                            style={{ width: "250px", height: "300px" }}
                    />
                    <br />
                    <Typography variant="body1" color="text.secondary" style={{marginTop:0, marginLeft: 4}}>
                        El producto <b>{getValues("legal_name")}</b> ha sido creado correctamente.
                    </Typography>
                    <br />
                    </div>
                </Grid>
                }
                </Grid>
            </DialogContentText>
            </DialogContent>
            { !confirm ?
            <DialogActions>
            <Button autoFocus onClick={handleClose} variant="contained" color="inherit">
                Cancelar
            </Button>
            <Button 
                onClick={handleSubmit(onSubmit)} 
                autoFocus variant="contained" 
                color="primary" 
                startIcon={ isLoading ? <CircularProgress size={20} color={"inherit"} /> : <AddIcon />}
                >
                Agregar
            </Button>
            </DialogActions>
            : "" }
        </Dialog>
    </Fragment>
  )
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
