import React from 'react';
import Typography from '@mui/material/Typography';
import { Divider, LinearProgress } from '@mui/material';
import { Link as MuiLink } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import axios from '../../components/Axios/Axios';
import { useParams } from 'react-router-dom';
import ClientList from '../../components/Clients/ClientList';

function Clientes() {
  const [isLoading, setIsLoading] = React.useState(true);
  const shouldLog = React.useRef(true);
  const { id } = useParams(); // Unpacking and retrieve id
  const [data , setData] = React.useState([]);

  const getData = async () => {
    const params = { org_id : id }
    const response = await axios.get("/clients" , { params: params } );
    setData(response.data);
    setIsLoading(false);
  }

  React.useEffect( ()=> {
    if(shouldLog.current){
      shouldLog.current = false;
      getData();
    }
  },[]);

  return (
    <div style={{marginTop: -80}}>
      <MuiLink to={`/organizations/`} component={RouterLink} underline="none" >
        <Button size='small' startIcon={<ArrowBackIcon />}> Back to Organizations</Button>
      </MuiLink>
        <h2>Clientes</h2>
        <Typography variant="body1" color="text.secondary" style={{marginTop:-20}}>
          Aquí podrás consultar el listado de clientes.
          <Divider />
        </Typography>
        { isLoading ? <LinearProgress /> : <ClientList clients={data} /> }
    </div>
  )
}

export default Clientes;