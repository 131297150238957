import React from 'react';
import { useParams } from "react-router-dom";
import axios from '../components/Axios/Axios';

// freeze data
import dataOrg from '../views/Organizations/Lista.json'

// getOrgDetail.js
const GetOrgDetail = () => {
    // 1. Get the userId param from the URL.
    let { id } = useParams();

    const [organization , setOrganization] = React.useState([]);
    const shouldLog = React.useRef(true);

    // 2. Prepare the request
    const getOrganization = async () => {
      await axios.get(`/organizations/${id}`)
      .then((response) =>{
        setOrganization(response.data.organization);
        //setIsLoading(false);
      }).catch((error) =>{
        console.error(error.message);
      });
    }

    // 3. Run the requesr
    React.useEffect(() => {
        if(shouldLog.current){
            //setOrganization( dataOrg.data.organizations.filter( obj => { return obj._id === id }) );
            getOrganization();
            shouldLog.current = false;
        }
        /*
        async function fetchUsers() {
    
          const fullResponse = await fetch('https://reqres.in/api/users');
    
          const responseJson = await fullResponse.json();
    
          usersSet(responseJson.data);
    
        }*/
        //fetchUsers();
    
      }, []);

      // 2
      return [organization];
};


export default GetOrgDetail;
