import React from 'react';
import { Typography } from '@mui/material';

export default function Footer() {
  return (
    <div style={{
        position: 'absolute',
        left: '50%',
        top: '98%',
        transform: 'translate(-50%, -50%)',
        width: '80%'}}
        align="center"
        >
            <Typography variant="body2" color="#bababa">
                © Facturelo 2023, All Rights Reserved.
            </Typography>

        </div>
  )
}
