import React, { useState } from 'react';
import { Button, Divider, LinearProgress, Link as MuiLink, Typography } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useParams } from 'react-router-dom';
import ReadProduct from '../../components/Products/ReadProduct';
import axios from '../../components/Axios/Axios';


export default function ProductDetails() {
  // 1. Read parameters
  const { id , product_id } = useParams(); // Unpacking and retrieve id and client id
  // for read client
  const [product , setProduct] = React.useState([]);
  const shouldLog = React.useRef(true);
  const [loading , setLoading] = useState(false);
  // 2. Prepare the request
  const getProduct = async () => {
    setLoading(true);
    await axios.get(`/products/view/${product_id}`)
    .then((response) =>{
        setProduct(response.data.product);
      setLoading(false);
    }).catch((error) =>{
      console.error(error.message);
    });
  }

  // 3. Run the request
  React.useEffect(() => {
    if(shouldLog.current){
      getProduct();
      shouldLog.current = false;
    }
  }, []);

  return (
    <div style={{marginTop: -80}}>
      <MuiLink to={`/organizations/${id}/productos`} component={RouterLink} underline="none" >
        <Button size='small' startIcon={<ArrowBack />}> Back to products</Button>
      </MuiLink> / Product details
      { loading ? <LinearProgress /> : <ReadProduct product={product} />  }
    </div>
  )
}
