import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { IconButton } from '@mui/material';
import DeleteCertificate from './delete/delete';
import * as LottiePlayer from "@lottiefiles/lottie-player";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.selectedOpacity,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

export default function ListCertificates(props) {
  return (
    <>
    
    { props.certificados.count > 0 && props.certificados.certificates.filter(item => item.is_active === true ).length > 0 &&
      <div>
    <h3>Listado de certificados</h3>
      <Typography variant="body1" color="text.secondary" style={{marginTop:-20}}>
      A continuación se muestra el listado de certificados activos de la organización.
      </Typography>
      <br />
      <TableContainer component={Paper}>
        <Table /*sx={{ minWidth: 650 }}*/ size="small" aria-label="a dense table">
          <TableHead>
            <TableRow style={{background: "rgba(0, 0, 0, 0.1)"}}>
              <TableCell>Nombre</TableCell>
              <TableCell align="center">RFC</TableCell>
              <TableCell align="center">Número de certificado</TableCell>
              <TableCell align="center">Desde</TableCell>
              <TableCell align="center">Hasta</TableCell>
              <TableCell align="center">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.certificados.certificates.filter(item => item.is_active === true ).map((item) => (
              <StyledTableRow
                key={item.certificate_number}
                //sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">{item.issuer_business_name}</TableCell>
                <TableCell align="center">{item.issuer_rfc}</TableCell>
                <TableCell align="center">{item.certificate_number}</TableCell>
                <TableCell align="center">{item.valid_from}</TableCell>
                <TableCell align="center">{item.valid_to}</TableCell>
                <TableCell align="center">{item.is_active ? <DeleteCertificate noCertificate={item.certificate_number} /> : <WarningIcon /> }</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> 
      </div>
    }
    { props.certificados.count <= 0 && props.certificados.certificates.filter(item => item.is_active === true ).length <= 0 &&
      <div style={{maxWidth: '100vh', alignItems:'center'}}>
      <h3>Sin certificado(s)</h3>
		  <br />
		  <Typography variant="body1" color="text.secondary" style={{marginTop:-20}}>
        Notámos que aún no subes tu certificado de sello digital, por favor toma en cuenta que es un requisito indispensable para 
        que tus facturas tengan validez fiscal. Utiliza la opción de <b>cargar certificado</b> para comenzar.
			</Typography>
			<lottie-player
				autoplay
				loop
				mode="normal"
				src="https://assets8.lottiefiles.com/private_files/lf30_rrpywigs.json"
				style={{ width: "250px", height: "250px" }}
				></lottie-player>
      </div>
    }
    </>
  );
}