import React from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import * as LottiePlayer from "@lottiefiles/lottie-player";
import AddProduct from '../Products/AddProduct';
import { IconButton, Link as MuiLink , Typography} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link as RouterLink } from "react-router-dom";

const columns = [
  { name: 'product_key', header: 'Clave producto SAT',defaultFlex: 3 },
  { name: 'description', header: 'Descripción producto', defaultFlex: 2 },
  { name: 'price' , header: 'Precio Unitario',  type: 'number' , textAlign: 'end', defaultFlex: 1 },
  { name: 'detail' , header: 'Ver detalles' , textAlign: 'center',
    render: ({ data }) => <MuiLink to={`/organizations/${data.org_id}/productos/${data._id}`} component={RouterLink} >
      <IconButton size='small'>
          <VisibilityIcon />
      </IconButton>
    </MuiLink>
  }
];

const filterValue = [
  { name: 'product_key', operator: 'contains', type: 'string', value: '' },
  { name: 'description', operator: 'startsWith', type: 'string', value: '' },
  { name: 'unit_key', operator: 'startsWith', type: 'string', value: '' },
  { name: 'unit_name'  , operator: 'startsWith', type: 'string', value: '' },
  { name: 'price'      , operator: 'gte', type: 'number', value: 0, },
];

const gridStyle = { minHeight: 525 , fontSize: 12 };

export default function ProductList(props) {
    const data = props.products;
  return (
    <div>
        { data.count > 0 ?
        <React.Fragment>
            <br />
            <AddProduct />
            <br /><br />
            <ReactDataGrid
                idProperty="id"
                showHoverRows={true}
                columns={columns}
                dataSource={data.products}
                style={gridStyle}
                defaultFilterValue={filterValue}
                defaultLimit={10}
                pagination={true}
            />
        </React.Fragment>
        : 
        <div align="center">
        <lottie-player
          autoplay
          loop
          mode="normal"
          src="https://assets1.lottiefiles.com/packages/lf20_c4udnllp.json"
          style={{ width: "300px", height: "300px" }}
          ></lottie-player>
          <Typography variant="body1" color="text.secondary">
            Aún no tienes productos o servicios, puedes comenzar creando uno nuevo
          </Typography>
          <br />
          <Typography variant="h6">
          <AddProduct />
          </Typography>
       </div>
        }
    </div>
    
  )
}
