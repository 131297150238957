import * as React from 'react';
import Typography from '@mui/material/Typography';
import { CardHeader, ListItemButton, ListItemAvatar, ListItem, Button, Alert , FormControlLabel, Radio,RadioGroup, CircularProgress} from '@mui/material';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import axios from '../Axios/Axios';

// form
import { useForm , Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({
  email: yup.string().email().required()
});

export default function AdvancedConfiguration(props) {

  const  item = props.item;
  const [anchor,setAnchor] = React.useState("right");
  const [isLoading, setIsLoading] = React.useState(false);
  const [user_granted , setUserGranted] = React.useState([]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  //form
  const { register, control, handleSubmit, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    //https://auth0.com/docs/api/management/v2#!/Users/post_users
    setIsLoading(true);
    let user_granted = JSON.stringify({
      "email": data.email,
      "name": data.name,
      "role": data.role,
      "org_id": props.item._id
  });
    let config = {
      method: 'post',
      url: '/organizations/members/add' , 
      headers: { 
        'Content-Type': 'application/json'
      },
      data : user_granted
    };

    await axios.request(config)
      .then((response) => {
        setUserGranted(response.data);
      })
      .catch((error) => {
        console.log(error);
        setUserGranted(error);
      });
      setIsLoading(false);
    
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 450 }}
      role="presentation"
      //onClick={toggleDrawer(anchor, false)}
      //onKeyDown={toggleDrawer(anchor, false)}
    >
      <br />
      <br />
        <Alert icon={false} color={"info"} sx={{fontSize: 12}}>
            <b>Access managment</b><br />
            Añadir roles y restricciones de acceso
        </Alert>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant={"body1"} p={2} >
          Dirección de correo electrónico <br />
            <Box
              sx={{
                  width: 500,
                  maxWidth: '100%',
              }}
              >
              <TextField
                id="standard-helperText"
                //label="Nombre de la cuenta"
                helperText={errors.email ? errors.email.message : ""} 
                variant="standard"
                sx={{width: '100%' }}
                placeholder={"contacto@facturelo.mx"}
                error={errors.email}
                size="small"
                focused={true}
                {...register("email")}
              />
            </Box>
              <br />
              Nombre de la cuenta (optional)<br />
              <Box
              sx={{
                  width: 500,
                  maxWidth: '100%',
              }}
              >
              <TextField
                id="standard-helperText"
                //label="Nombre de la cuenta"
                helperText={errors.name ? "Capture el nombre de la organización. " + errors.name.message : ""} 
                variant="standard"
                sx={{width: '100%' }}
                placeholder={"Erick Ayala Delgadillo"}
                error={errors.name}
                size="small"
                focused={true}
                {...register("name")}
              />
            </Box>
            <br />
            Rol de acceso <br />
            <Controller
              control={control}
              name="role"
              defaultValue="Lector"
              render={({ field }) => (
                <RadioGroup {...field} >
                  <FormControlLabel
                    value="Administrador"
                    control={<Radio />}
                    label="Administrador"
                  />
                  <FormControlLabel
                    value="Editor"
                    control={<Radio />}
                    label="Editor"
                  />
                  <FormControlLabel
                    value="Lector"
                    control={<Radio />}
                    label="Lector"
                  />
                </RadioGroup>
              )}
            />
            
            <br />
              <Button 
              variant="contained" 
              color="primary" 
              startIcon={ isLoading ? <CircularProgress size={20} color={"inherit"} /> : <AddIcon />}
              onClick={handleSubmit(onSubmit)} >Grant access</Button>
        </Typography>
      </form>
      <Divider />
      { Object.keys(user_granted).length > 0 ? 
      <Typography variant={"body1"} p={1} > 
        { user_granted === "Request failed with status code 409" ? 
        <Alert icon={false} color={"error"} sx={{fontSize: 12}}>El usuario <b>{getValues("email")}</b> ya existe. </Alert>
        : 
        <Alert icon={false} color={"info"} sx={{fontSize: 12}}>Ha sido enviado un correo de verificación al usuario {user_granted.name} <b>({user_granted.email})</b>.</Alert>
        }
      </Typography> : ""
      }
      {/*
      <List>
          <ListItem key={"1"}>
            <ListItemButton>
              <ListItemText primary={"al102964@gmail.com"} secondary={"Administrador"} />
            </ListItemButton>
          </ListItem>
          <ListItem key={"2"}>
            <ListItemButton>
              <ListItemText primary={"julio.valdiviezo@gmail.com"} secondary={"Editor"} />
            </ListItemButton>
          </ListItem>
          <ListItem key={"3"}>
            <ListItemButton>
              <ListItemText primary={"daniel.luna.chavero@gmail.com"} secondary={"Lector"} />
            </ListItemButton>
          </ListItem>
      </List>
      */}
    </Box>
  );

  return (
    <div>
    <h3>Advanced configuration</h3>
        <ListItem
            sx={{background: "rgb(210, 252, 252)"}}
            secondaryAction={ <Button autoFocus variant="contained" startIcon={<SettingsSuggestIcon />} color="primary" onClick={toggleDrawer(anchor, true)}>Manage</Button> }
            disablePadding
        >
            <ListItemButton onClick={toggleDrawer(anchor, true)}>
                <ListItemAvatar>
                    <CardHeader sx={{height:75}}
                    title={<Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{"Access management"}</Typography>}
                    subheader={"Esta acción permite gestionar accesos, roles y permisos para usuarios dentro de la organización " + item.legal.name + "."}
                    />
                </ListItemAvatar>
            </ListItemButton>
        </ListItem>
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
    </div>
  );
}