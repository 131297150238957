import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import moment from 'moment';
import * as LottiePlayer from "@lottiefiles/lottie-player";
import Avatar from '@mui/material/Avatar';
import { Button, CardHeader, Grid, Typography } from '@mui/material';
import AddClient from './AddClient';
import { Link as MuiLink } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";

export default function ClientList(props) {
  const data = props.clients;
  const [checked, setChecked] = React.useState([1]);
  let count = 0;

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
      { data.count > 0 ?
      <React.Fragment>
        <AddClient />
        <br /><br />
            { data.customers.map( client => {
              const labelId = client.legal_name;
              count = count + 1;
              return (
                <MuiLink to={`/organizations/${client.org_id}/clientes/${client._id}`} component={RouterLink} underline="none" >
                  <ListItem
                    key={client.legal_name}
                    secondaryAction={
                      <Button variant="contained" size="small">
                        Ver { /*moment(client.createdAt).format('DD/MM/YYYY') */}
                      </Button>
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemAvatar>
                        <CardHeader sx={{height:75}}
                          avatar={
                            <Avatar 
                              sx={{ bgcolor: client.logo_url !== null ? "#fefefe" : "rgb(5, 30, 52)" , width: 48, height: 48 }} 
                              aria-label="recipe" 
                              src={ client.logo_url !== null ? client.logo_url : ""}
                              variant="rounded" >
                              {client.legal_name.charAt(0).toUpperCase()}
                            </Avatar>
                          }
                          title={<Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{client.legal_name}</Typography>}
                          subheader={client.tax_id}
                        />
                      </ListItemAvatar>
                    </ListItemButton>
                  </ListItem>
                </MuiLink>
              );
            })}
      </React.Fragment>
      : 
      <div align="center">
        <lottie-player
          autoplay
          loop
          mode="normal"
          src="https://assets1.lottiefiles.com/packages/lf20_c4udnllp.json"
          style={{ width: "300px", height: "300px" }}
          ></lottie-player>
          <Typography variant="body1" color="text.secondary">
            Aún no tienes clientes, puedes comenzar creando uno nuevo
          </Typography>
          <br />
          <Typography variant="h6">
          <AddClient />
          </Typography>
       </div>
      }
    </List>
  );
}