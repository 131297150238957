import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import { Button, TextField, Grid, Typography, Alert, Snackbar, CardHeader,  ListItemButton, ListItemAvatar, ListItem, Dialog, DialogContent, DialogContentText, DialogActions, Autocomplete, CircularProgress, touchRippleClasses, Select, IconButton, Paper, Avatar, Divider } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { Link as MuiLink } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import SwitchProductIcon from '@mui/icons-material/FindReplace';
import InvoiceIcon from '@mui/icons-material/Description';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useForm  } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Tooltip from '@mui/material/Tooltip';
import * as yup from "yup";
import { useAuth0 } from '@auth0/auth0-react';
import { useParams, useNavigate } from 'react-router-dom';
import DeleteProduct from "../Products/DeleteProduct";
import axios from '../Axios/Axios';
import SearchProduct from "../Products/SearchProduct";
import Items from "./Items";

// for date
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
const moment = require("moment-timezone");

const schema = yup.object({
    tax_id: yup.string().required(),
    tax_system: yup.string().required(),
    zip: yup.string().required(),
    legal_name: yup.string().required(),
    payment_form: yup.string().required(),
    payment_method: yup.string().required(),
    use: yup.string().required(),
    currency: yup.string().required()
});

export default function AddInvoice(props) {
  const { getAccessTokenSilently } = useAuth0();
  //for autocomplete to product and services
  const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = openAutocomplete && options.length === 0;
  const [client,setClient] = React.useState([]);

  //for cat unit
  const [openAutoUnit, setOpenAutoUnit] = React.useState(false);
  const [optionsUnit, setOptionsUnit] = React.useState([]);
  const loadingUnit = openAutoUnit && optionsUnit.length === 0;

  const [open, setOpen] = React.useState(false);
  const [openClient, setOpenClient] = React.useState(false);
  const [openCatUnit, setOpenCatUnit] = React.useState(false);
  const { id } = useParams(); // Unpacking and retrieve id
  const navigate = useNavigate();

  //for date
  //const [value, setValue] = React.useState(dayjs('2022-04-07'));

  //data from child
  const [childData,setChildData] = React.useState([]);


  //const  product = props.product;
  // tax
  const [tax] = React.useState("002");
  
  const { register, handleSubmit, getValues, setValue, formState: { errors, isDirty } } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data) => {
    //const token = await getAccessTokenSilently();
    data.products = {
        total_items: childData.rows.length,
        total_amount: childData.total,
        items: childData.rows
    }
    
    const newInvoice = {
        type:  "I",
        payment_form: data.payment_form,
	    use: data.use,
	    payment_method: data.payment_method,
	    currency: data.currency,
        //exchange: data.currency !== "MXN" ? data.exchange : null,
        customer: {
            tax_id: data.tax_id,
            legal_name: data.legal_name,
            tax_system: data.tax_system,
            address: { 
                zip: data.zip 
            },
        },
        items: data.products.items,
        total: data.products.total_amount,
        org_id: id
    }
    //axios.defaults.headers['Authorization'] = `Bearer ${token}`;
    await axios.post('/invoices' , newInvoice)
    .then((repon) => {
        setOpen(true);
        setTimeout(() => {
            handleClose();
            navigate(`/organizations/${id}/facturas`, { replace: true });
        }, 3000);
    })
    
    .catch((error) =>{
        alert(error);
    })
  };

  const handleClose = () => {
    setOpen(false);
  };

  // to get client list
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }
    
    (async () => {
      //await sleep(1e3); // For demo purposes.
      const params = { org_id : id }
      if (active) {
        await axios.get('/clients' , { params: params })
        .then((results) =>{
          setOptions(results.data.customers);
        })
        .catch((error) =>{
          console.error(error);
        })
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!openAutocomplete) {
        //setOptions([]);
    } 
  }, [openAutocomplete]);

  // to get unit catalog
  React.useEffect(() => {
    let active = true;

    if (!loadingUnit) {
      return undefined;
    }
    
    (async () => {
      //const params = { org_id : id }
      if (active) {
        await axios.get('/products/catclaveunidad' /*, { params: params }*/)
        .then((results) =>{
          setOptionsUnit(results.data);
        })
        .catch((error) =>{
          console.error(error);
        })
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingUnit]);

  React.useEffect(() => {
    if (!openAutoUnit) {
        setOptionsUnit([]);
    } 
  }, [openAutoUnit]);

  const SwitchClient = () => (
    <Tooltip title="Seleccionar cliente">
        <IconButton onClick={handleOpenDialogClient}>
        <SwitchProductIcon />
        </IconButton>
    </Tooltip>
  );

  const handleOpenDialogClient = () => {
    setOpenClient(true);
  }

  const handleCloseDialogClient = () => {
    setOpenClient(false);
  };

  const handleAssingNewKey = () => {
    if(getValues("client"))
    {
        const clientSelected = options.find(item => item.tax_id === getValues("client").split(" - ")[0]);
        setClient(clientSelected);
        setValue('tax_id',clientSelected.tax_id);
        setValue('legal_name',clientSelected.legal_name);
        setValue('tax_system',clientSelected.tax_system);
        setValue('zip',clientSelected.address.zip);
    }
    
    handleCloseDialogClient();
  }

  // for unit catalog
  const SwitchUnit = () => (
    <Tooltip title="Buscar y reemplazar unidad de medida">
        <IconButton onClick={handleOpenDialogUnit}>
            <SwitchProductIcon />
        </IconButton>
    </Tooltip>
  );

  const handleOpenDialogUnit = () => {
    setOpenCatUnit(true);
  }

  const handleCloseDialogUnit = () => {
    setOpenCatUnit(false);
  };

  const handleAssingNewUnit = () => {
    if(getValues("unit_key_new"))
        //setUnitKey(getValues("unit_key_new"));
    
    handleCloseDialogUnit();
  }
  
  const eventhandler = data => setChildData(data);

  const [currency,setCurrency] = React.useState("");
  const handleChange = (event) => {
    setCurrency(event.target.value);
    console.log(
      "event.target ==>",
      event.target,
      "event.target.name ==>",
      event.target.name
    );
  };

  return (
    <div style={{marginTop: -80}}>
    <form onSubmit={handleSubmit(onSubmit)}>
        <Fragment>
        <MuiLink to={`/organizations/${id}/facturas`} component={RouterLink} underline="none" >
            <Button size='small' startIcon={<ArrowBackIcon />}> Back to Facturas</Button>
        </MuiLink> / Nueva factura
        <CardHeader sx={{height:175 , marginTop: -7 , marginBottom: -6}}
          avatar={
              <Avatar 
                sx={{ bgcolor: "#051e34" , width: 48, height: 48 }} 
                aria-label="recipe" 
                //src={ product.logo_url !== null ? product.logo_url : ""}
                variant="rounded" >
                <InvoiceIcon />
              </Avatar>
          }
          title={
          <Typography variant="h6" style={{fontWeight: 'bold'}}>
            Agregar factura
            <Divider />
          </Typography>
        }
          subheader={"Configure la siguiente información para crear una nueva factura."}
        />
        {/*
        {errors.product_key ? 
            <Alert severity="info">
                Por favor, asegúrese que la clave de producto o servicio es la correcta.
            </Alert>: 
        ""}*/}
        <Alert severity="info" icon={false}>
            1. Datos generales de la factura
        </Alert>
        <br />
        <Grid container spacing={0}>
            <Grid xs={5} >            
                <Grid xs={12}>
                <TextField
                    id="filled-select-currency-native"
                    label="RFC del cliente *"
                    size="small"
                    value={client?.tax_id}
                    sx={{width: '34%' }}
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{ shrink: true }} 
                    error={errors.tax_id}
                    //defaultValue={product.description}
                    variant="filled"
                    {...register("tax_id")}
                    >
                </TextField> &nbsp;
                    <TextField
                        id="product_key"
                        label="Nombre del cliente *"
                        size="small"
                        value={client?.legal_name}
                        sx={{width: '60%' }}
                        InputProps={{
                            readOnly: true,
                            endAdornment: <SwitchClient />
                        }}
                        error={errors.legal_name}
                        InputLabelProps={{ shrink: true }}
                        variant="filled"
                        {...register("legal_name")}
                    />
                </Grid>
                <br />
                <Grid xs={12}>
                <TextField
                    id="filled-select-currency-native"
                    label="Código postal del cliente *"
                    size="small"
                    sx={{width: '35%' }}
                    InputProps={{
                        //readOnly: true
                    }}
                    error={errors.zip}
                    value={client?.address?.zip}
                    InputLabelProps={{ shrink: true }}
                    variant="filled"
                    {...register("zip")}
                    >
                </TextField>
                &nbsp;
                <TextField
                    id="filled-select-currency-native"
                    label="Régimen Fiscal del cliente *"
                    size="small"
                    sx={{width: '60%' }}
                    InputProps={{
                        //readOnly: true
                    }}
                    InputLabelProps={{ shrink: true }}
                    error={errors.tax_system}
                    value={client?.tax_system}
                    variant="filled"
                    {...register("tax_system")}
                    >
                </TextField>
                

                </Grid> 
                {/*
                <h3>Unidad de medida</h3> 
                <Grid xs={12}> 
                    <TextField
                    id="unit-key-id"
                    label="Clave de unidad"
                    InputProps={{
                        readOnly: true,
                        endAdornment: <SwitchUnit />
                    }}
                    size="small"
                    sx={{width: '96%' }}
                    error={errors.unit_key}
                    //value={unitKey}
                    //defaultValue={unitKey}
                    variant="filled"
                    {...register("unit_key")}
                    >
                    </TextField>
                </Grid>
                */}
            </Grid>
            
            <Grid xs={7}> 
                <Grid xs={12}> 
                <TextField
                    id="select-taxes"
                    size="small"
                    select
                    label="Forma de pago *"
                    sx={{width: '45%' }}
                    error={errors.payment_form}
                    //onChange={handleChange}
                    //defaultValue={ typeof product.taxes?.type === "undefined" ? tax : product.taxes?.type }
                    //SelectProps={{
                    //    native: true,
                    //}}
                    variant="filled"
                    {...register("payment_form")}
                    InputLabelProps={{ shrink: true }} 
                    >
                    {paymentFormCat.map((option) => (
                        <MenuItem 
                        key={option.payment_form_key} 
                        value={ option.payment_form_key } 
                        selected={ tax === option.value ? true : false } 
                        >
                        { option.payment_form_key + " - " + option.payment_form_description }
                        </MenuItem>
                    ))}
                </TextField>
                    &nbsp;
                    <TextField
                    id="select-taxes"
                    size="small"
                    select
                    label="Uso del comprobante *"
                    sx={{width: '54%' }}
                    error={errors.use}
                    //onChange={handleChange}
                    //defaultValue={ typeof product.taxes?.type === "undefined" ? tax : product.taxes?.type }
                    //SelectProps={{
                    //    native: true,
                    //}}
                    variant="filled"
                    {...register("use")}
                    InputLabelProps={{ shrink: true }} 
                    >
                    {useCat.map((option) => (
                        <MenuItem 
                        key={option.use_key} 
                        value={ option.use_key } 
                        selected={ tax === option.value ? true : false } 
                        >
                        { option.use_key + " - " + option.use_description }
                        </MenuItem>
                    ))}
                </TextField>
                </Grid>
                <br />
                <Grid xs={12}>
                <TextField
                    id="select-taxes"
                    size="small"
                    select
                    label="Método de pago *"
                    sx={{width: '45%' }}
                    error={errors.payment_method}
                    variant="filled"
                    {...register("payment_method")}
                    InputLabelProps={{ shrink: true }} 
                    >
                    {paymentMethod.map((option) => (
                        <MenuItem 
                        key={option.payment_method_key} 
                        value={ option.payment_method_key } 
                        selected={ "PUE" === option.value ? true : false } 
                        >
                        { option.payment_method_key + " - " + option.payment_method_description }
                        </MenuItem>
                    ))}
                    </TextField>&nbsp;
                <TextField
                    id="select-taxes"
                    size="small"
                    select
                    label="Moneda *"
                    sx={{width: '29%' }}
                    error={errors.currency}
                    variant="filled"
                    {...register("currency")}
                    InputLabelProps={{ shrink: true }} 
                    onChange={handleChange}
                    >
                    {currencyCat.map((option) => (
                        <MenuItem 
                        key={option.currency_key} 
                        value={ option.currency_key } 
                        selected={ currency === option.value ? true : false } 
                        >
                        { option.currency_key + " - " + option.currency_name }
                        </MenuItem>
                    ))}
                </TextField>
                &nbsp;
                <TextField
                    id="select-taxes"
                    size="small"
                    label="Tipo de cambio"
                    sx={{width: '24%' }}
                    error={errors.exchange}
                    variant="filled"
                    {...register("exchange")}
                    InputLabelProps={{ shrink: true }} 
                    InputProps={{
                        readOnly: currency !== "MXN" && currency !== "" ? false : true
                    }}
                    disabled={ currency !== "MXN" && currency !== "" ? false : true }
                    >
                </TextField>
                </Grid>
                <br />
                {/*
                <Grid xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        renderInput={(props) => 
                            <TextField {...props} 
                            {...register("date")}
                            InputLabelProps={{ shrink: true }}
                            variant="filled"
                            sx={{width: '40%' }}
                            size="small"
                            />
                        }
                        label="Fecha de emisión *"
                        inputFormat="YYYY-MM-DDThh:mm:ss" // 13-09-2022
                        onChange={(newValue) => {
                            setValue('date' , moment().tz("America/Mexico_City").format());
                        }}
                        //value={moment().tz("America/Mexico_City").format()}
                        //defaultValue={moment().tz("America/Mexico_City").format()}
                        
                    />
                </LocalizationProvider>
                {/*
                <TextField
                    id="standard-helperText"
                    label="Fecha de emisión"
                    //defaultValue={product.createdAt}
                    variant="filled"
                    sx={{width: '40%' }}
                    placeholder={"2023-01-03T22:40:27.510Z"}
                    error={errors.date}
                    size="small"
                    {...register("date")}
                    InputLabelProps={{ shrink: true }} 
                    />} &nbsp;
                <TextField
                    id="standard-helperText"
                    label="Folio"
                    defaultValue={""}
                    variant="filled"
                    sx={{width: '29%' }}
                    placeholder={"00001"}
                    error={errors.folio}
                    size="small"
                    {...register("folio")}
                    InputLabelProps={{ shrink: true }} 
                />
                &nbsp;
                <TextField
                    id="standard-helperText"
                    label="Serie"
                    defaultValue={""}
                    variant="filled"
                    sx={{width: '29%' }}
                    placeholder={"TS"}
                    error={errors.serie}
                    size="small"
                    {...register("serie")}
                    InputLabelProps={{ shrink: true }} 
                />
                </Grid>
                */}
            </Grid>
        </Grid>
        
        <Alert severity="info" icon={false}>
            2. Desglose de productos o servicios
        </Alert>
        <br />
        <Items onChange={eventhandler} />
        <br />
        <div align="left">
            <Grid xs={12}>
                <Button color="primary" variant="outlined" onClick={()=> navigate(`/organizations/${id}/facturas`, { replace: true }) }>Regresar</Button>
                &nbsp; 
                <Button type="submit" color="primary" variant="contained" disabled={ client?.tax_id && childData.rows.length > 0 && childData.total > 0 ? false : true }>Guardar</Button>
                &nbsp; 
                <Typography variant="caption" color="text.secondary" style={{marginLeft: 4}}>
                    {/*last updated <b>product.updatedAt</b>*/}
                </Typography>
            </Grid>
            {/*JSON.stringify(typeof childData.rows?.product_key)*/}
            {/*
            <br />
            <Grid xs={12}>
                <h3>Danger zone</h3>
                    <ListItem
                        //key={product.legal_name}
                        sx={{background: "rgb(255, 238, 237)"}}
                        secondaryAction={ <DeleteProduct product_key={product.product_key} />}
                        disablePadding
                    >
                        <ListItemButton>
                            <ListItemAvatar>
                                <CardHeader sx={{height:75}}
                                title={<Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{"Eliminar producto"}</Typography>}
                                subheader={"Esta acción eliminará permanentemente al producto. Clic en el botón eliminar para continuar con esta acción."}
                                /> 
                            </ListItemAvatar>
                        </ListItemButton>
                    </ListItem>
            </Grid>
            */}
        </div>
        </Fragment>
    
    <Fragment>
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogContent>
            <DialogContentText>
                <Grid container spacing={1}>
                    <Grid item xs={12} sx={{/*background: "#FAFAFA"*/}}>
                    <div align="center">
                    <h3 style={{marginLeft: 4, color: "#000"}}>Factura creada</h3>
                    <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src="https://assets3.lottiefiles.com/packages/lf20_hFmz66beuw.json"
                            style={{ width: "200px", height: "200px" }}
                    />
                    <br />
                    <Typography variant="body1" color="text.secondary" style={{marginTop:0, marginLeft: 4}}>
                        La factura ha sido creada correctamente. <br />
                        Por favor, espere unos instantes mientras se realiza el <b>proceso de timbrado.</b>
                    </Typography>
                    <br />
                    </div>
                    </Grid>
                </Grid>
            </DialogContentText>
            </DialogContent>
            {/*
            <DialogActions>
                <Button variant="outlined" onClick={handleCloseDialogClient}>Continuar</Button>
                <Button variant="contained" onClick={handleAssingNewKey}>Timbrar</Button>
            </DialogActions>
            */}
        </Dialog>
    </Fragment>

    {/** Buscar clave de producto o servicio SAT */}
    <Fragment>
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={openClient}
            onClose={handleCloseDialogClient}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogContent>
            <DialogContentText>
                <Grid container spacing={1}>
                    <Grid item xs={5} sx={{/*background: "#FAFAFA"*/}}>
                    <div align="center">
                    <h3 style={{marginLeft: 4, color: "#000"}}>Seleccionar cliente</h3>
                    <Typography variant="body2" color="text.secondary" style={{marginTop:-14, marginLeft: 4}}>
                        Seleccione al receptor de la factura. 
                    </Typography>
                    <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src="https://assets3.lottiefiles.com/packages/lf20_z3wd7moi.json"
                            style={{ width: "445px" }}
                    />
                    
                    </div>
                    </Grid>
                    <Grid item xs={7}>
                    <Autocomplete
                            id="asynchronous-demo"
                                sx={{ width: '95%' , marginLeft: 4 , marginTop: "2vh" }}
                                open={openAutocomplete}
                                onOpen={() => {
                                    setOpenAutocomplete(true);
                                }}
                                onClose={() => {
                                    setOpenAutocomplete(false);
                                }}
                                isOptionEqualToValue={(option, value) => option.description === value.description }
                                getOptionLabel={(option) => option.tax_id + " - " +option.legal_name }
                                options={options}
                                loading={loading}
                                //defaultValue={{ product_key: String(product.product_key).split(" - ")[0] , description: String(product.product_key).split(" - ")[1] }}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label="Ingrese rfc o nombre del cliente"
                                    variant="filled"
                                    helperText={getValues("client")}
                                    //defaultValue={{ product_key: String(product.product_key).split(" - ")[0] , description: String(product.product_key).split(" - ")[1] }}
                                    error={errors.client}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                        ),
                                    }}
                                    {...register("client")}
                                    />
                                )}
                        />
                    
                    </Grid>
                </Grid>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCloseDialogClient}>Cancelar</Button>
                <Button variant="contained" onClick={handleAssingNewKey}>Seleccionar</Button>
            </DialogActions>
        </Dialog>
    </Fragment>

    {/** Buscar clave de unidad SAT */}
    <Fragment>
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={openCatUnit}
            onClose={handleCloseDialogUnit}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogContent>
            <DialogContentText>
                <Grid container spacing={1}>
                    <Grid item xs={5} sx={{/*background: "#FAFAFA"*/}}>
                    <div align="center">
                    <h3 style={{marginLeft: 4, color: "#000"}}>Reemplazar clave de unidad de medida</h3>
                    <Typography variant="body2" color="text.secondary" style={{marginTop:-14, marginLeft: 4}}>
                        Seleccione la nueva clave de unidad de medida. 
                    </Typography>
                    <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src="https://assets1.lottiefiles.com/packages/lf20_3cmzoitz.json"
                            style={{ width: "445px" }}
                    />
                    
                    </div>
                    </Grid>
                    <Grid item xs={7}>
                    <Autocomplete
                        id="unit-name-id"
                            sx={{ width: '96%' , marginLeft: 0 }}
                            open={openAutoUnit}
                            onOpen={() => {
                                setOpenAutoUnit(true);
                            }}
                            onClose={() => {
                                setOpenAutoUnit(false);
                            }}
                            isOptionEqualToValue={(option, value) => option.Nombre === value.Nombre }
                            getOptionLabel={(option) => option.c_ClaveUnidad + " - " + option.Nombre}
                            options={optionsUnit}
                            loading={loadingUnit}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label="Ingrese clave o nombre de unidad de medida "
                                variant="filled"
                                helperText={getValues("unit_key_new")}
                                {...register("unit_key_new")}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {loadingUnit ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }}
                                />
                            )}
                        /> 
                    </Grid>
                </Grid>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCloseDialogUnit}>Cancelar</Button>
                <Button variant="contained" onClick={handleAssingNewUnit}>Reemplazar</Button>
            </DialogActions>
        </Dialog>
    </Fragment>
    </form>
    </div>
  );
}

//tax catalog
const catTaxes = [
    {
        id: '001',
        rate: 0.0,
        type: 'IVA',
        description: 'IVA (0.00%)',
        factor: "Tasa"
    },
    {
        id: '002',
        rate: 0.16,
        type: 'IVA',
        description: 'IVA (16.00%)',
        factor: "Tasa"
    },
    {
        id: '003',
        rate: 0.0,
        type: 'Exento',
        description: 'Exento (0.00%)',
        factor: "Exento"
    }
];

//payment form cat
const paymentFormCat = [
    {
        payment_form_key: "01",
        payment_form_description: "Efectivo",
    },
    {
        payment_form_key: "02",
        payment_form_description: "Cheque nominativo",
    },
    {
        payment_form_key: "03",
        payment_form_description: "Transferencia electrónica de fondos",
    },
    {
        payment_form_key: "04",
        payment_form_description: "Tarjeta de crédito",
    },
    {
        payment_form_key: "05",
        payment_form_description: "Monedero electrónico",
    }
    ,
    {
        payment_form_key: "06",
        payment_form_description: "Dinero electrónico",
    },
    {
        payment_form_key: "08",
        payment_form_description: "Vales de despensa",
    },
    {
        payment_form_key: "12",
        payment_form_description: "Dación en pago",
    },
    {
        payment_form_key: "13",
        payment_form_description: "Pago por subrogación",
    },
    {
        payment_form_key: "14",
        payment_form_description: "Pago por consignación",
    },
    {
        payment_form_key: "99",
        payment_form_description: "Por definir",
    }
];

// uso del comprobante
const useCat = [
    {
        use_key: "G01",
        use_description: "Adquisición de mercancías"
    },
    {
        use_key: "G02",
        use_description: "Devoluciones, descuentos o bonificaciones"
    },
    {
        use_key: "G03",
        use_description: "Gastos en general"
    }
];

// payment method cat
const paymentMethod = [
    {
        payment_method_key: 'PUE',
        payment_method_description: 'Pago en una sola exhibición'
    },
    {
        payment_method_key: 'PPD',
        payment_method_description: 'Pago en parcialidades o diferido'   
    }
]

// currency 
const currencyCat = [
    {
        currency_key: "AED",
        currency_name: "Dirham de EAU"
    },
    {
        currency_key: "AUD",
        currency_name: "Dólar Australiano"
    },
    {
        currency_key: "MXN",
        currency_name: "Peso Mexicano"
    },
    {
        currency_key: "USD",
        currency_name: "Dólar Americano"
    }
]